<template>
    <div class="container">
        <div class="leftnav">
            <div class="nav-list">
                <section class="nav-list-item" v-for="(item1, index1) in list" :key="index1">
                    <div class="nav-list-item-h nav-list-h1" :class="isNavActive == index1 ? 'active-nav' : ''" @click="jump('h' + item1.anchor, index1)">
                        <div class="icon-box">
                            <div class="diamond"></div>
                        </div>
                        <div>{{ index1 + 1 + '.' }}</div>
                        <div>{{ item1.h1 }}</div>
                    </div>
                    <div class="nav-list-item-h nav-list-h2" v-for="(item2, index2) in item1.h2box" :key="index2" :class="isNavActive == index1 + '_' + index2 ? 'active-nav' : ''" @click="jump('h' + item2.anchor, index1 + '_' + index2)">
                        <div class="icon-box">
                            <div class="round"></div>
                        </div>
                        <div>{{ index1 + 1 + '.' + (index2 + 1) }}</div>
                        <div>{{ item2.h2 }}</div>
                    </div>
                </section>
            </div>
        </div>
        <main class="main">
            <section v-for="(cont1, i1) in list" :key="i1">
                <!-- 纯数字id无法通过querySelector|getElementById获取元素 -->
                <h1 :id="'h' + cont1.anchor" class="main-list-h1">{{ i1 + 1 + '.' + cont1.h1 }}</h1>
                <section :id="'h' + cont2.anchor" class="main-list-box2" v-for="(cont2, i2) in cont1.h2box" :key="i2">
                    <h2 class="main-list-h2">
                        <span>{{ i1 + 1 + '.' + (i2 + 1) }}</span>
                        <span>{{ cont2.h2 }}</span>
                    </h2>
                    <div class="main-list-h2-content">{{ cont2.content }}</div>
                    <section class="main-list-box3" v-for="(cont3, i3) in cont2.h3box" :key="i3">
                        <h3 class="main-list-h3">
                            <span>{{ i1 + 1 + '.' + (i2 + 1) + '.' + (i3 + 1) }}</span>
                            <span>{{ cont3.h3 }}</span>
                        </h3>
                        <article class="main-list-content">{{ cont3.content }}</article>
                    </section>
                </section>
            </section>
        </main>
    </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll.js'
import { manual } from './data.js'
export default {
    data() {
        return {
            isNavActive: -1,
            list: []
        }
    },
    created() {
        //没有制定跳转目录，则默认显示最顶端目录
        /**
         * id: 内容区域，一级标题，二级标题 h + anchor【data.js中】
         * navIndex：目录区域，一级标题index1【0开始】;二级标题 index1 + '_' + index2【0_0开始】
         * **/
        this.urlId = this.$route.params.id ? this.$route.params.id : ''
        this.navIndex = this.$route.params.navIndex ? this.$route.params.navIndex : ''
        this.list = manual
    },
    mounted() {
        this.$nextTick(() => {
            if (this.urlId && this.urlId != '') {
                document.getElementById(this.urlId).scrollIntoView({
                    //平滑滚动到id对应点
                    behavior: 'smooth'
                })
            }
            if (this.navIndex && this.navIndex != '') {
                this.isNavActive = this.navIndex
            }
        })
    },
    methods: {
        handleClick(e) {
            // console.log(document.getElementById(e).offsetTop)
            // console.log(this.$el.querySelector('#' + e))
            /**
             * 动画垂直滚动到页面指定位置
             * HTML5选择scrollIntoView(@param)作为控制页面滚动的标准方法
             * @param { Boolean } true |不传  ，窗口滚动之后会让调动元素顶部和视窗顶部尽可能齐平
             * @param { Boolean } false  ,调用元素会尽可能全部出现在视口中（可能的话，调用元素的底部会与视口的顶部齐平。）不过顶部不一定齐平
             **/
            // this.$el.querySelector('#' + e).scrollIntoView() //瞬间移动到id对应点
            // document.querySelector('#' + e).scrollIntoView({//平滑滚动到id对应点
            //     behavior: 'smooth'
            // })
        },
        jump(e, index) {
            this.isNavActive = index
            let currentY = document.body.scrollTop //当前位置
            let targetY = document.getElementById(e).offsetTop //目标位置
            scrollTo(currentY, targetY)
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    max-width: 1200px;
    height: 100%;
    min-height: 705px;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: Source Han Sans CN;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #333333;
    .leftnav {
        background-color: #f2f5f9;
        border: solid 1px #dbdbdb;
        position: absolute;
        height: 100%;
        width: 207px;
        box-sizing: border-box;
        .nav-list {
            width: 205px;
            // min-width: 100px;
            padding-top: 60px;
            padding-left: 24px;
            // position: absolute;
            position: sticky;
            top: 0;
            .nav-list-item {
                .nav-list-item-h {
                    // width: 179px;
                    // min-width: 100px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    .icon-box {
                        width: 40px;
                        display: flex;
                        justify-content: center;
                    }
                    div:nth-child(2) {
                        margin-right: 10px;
                    }
                    div:nth-child(3) {
                        flex: 1;
                    }
                    a {
                        color: #333333;
                    }
                    .diamond {
                        width: 20px;
                        height: 20px;
                        margin: 10px 0 0 10px;
                        transform-origin: 0 100%;
                        transform: rotate(-45deg);
                        background: #6c9ce9;
                    }

                    .diamod::after {
                        content: '';
                        position: absolute;
                        top: 10px;
                        left: -7px;
                        border: 7px solid transparent;
                        border-top: 10px solid #000;
                    }
                    .round {
                        width: 7px;
                        height: 7px;
                        border-radius: 4px;
                        background-color: #6c9ce9;
                    }
                }
                .nav-list-h1 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 17px;
                }
                .nav-list-h2 {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                .active-nav {
                    color: #357df1 !important;
                    background-image: linear-gradient(270deg, rgba(53, 125, 241, 0.2) 0%, rgba(53, 125, 241, 0.02) 100%);
                }
                .active-nav .a {
                    color: #357df1 !important;
                }
                .active-nav::after {
                    content: '';
                    width: 4px;
                    height: 44px;
                    background-color: #357df1;
                    display: inline-block;
                }
            }
        }
    }
    .main {
        padding-top: 60px;
        margin-left: 240px;
        .main-list-h1 {
            // height: 24px;
            font-size: 24px;
            line-height: 24px;
            // color: #333333;
            margin-bottom: 24px;
        }
        .main-list-box2 {
            .main-list-h2 {
                font-size: 18px;
                line-height: 18px;
                padding-bottom: 9px;
                border-bottom: 2px solid #e7e7e7;
            }
            .main-list-h2-content {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                padding-top: 16px;
                text-indent: 2em;
                line-height: 37px;
            }
            .main-list-box3 {
                padding: 26px 0;
                .main-list-h3 {
                    font-size: 18px;
                    line-height: 18px;
                    margin-bottom: 27px;
                }
                .main-list-content {
                    // width: 970px;
                    font-family: Source Han Sans CN;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 37px;
                    letter-spacing: 0px;
                    color: #333333;
                    text-indent: 2em;
                }
            }
        }
    }
}
</style>
