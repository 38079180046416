import echarts from 'echarts'
import _ from 'lodash'
let colorEven = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
    {
        offset: 0,
        color: 'rgba(36, 117, 254, 0.1)'
    },
    {
        offset: 1,
        color: '#2dfff8'
    }
])
let colorOdd = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
    {
        offset: 0,
        color: 'rgba(36, 117, 254, 0.1)'
    },
    {
        offset: 1,
        color: '#2475fe'
    }
])
export default visitSourceList => {
    let source = []
    visitSourceList = _.sortBy(visitSourceList, function(item) {
        return -item.visitNum
    })
    visitSourceList.forEach(item => {
        if (source.length <= 8) {
            //最多显示9条
            source.push({
                city: item.name,
                count: item.visitNum || 0
            })
        }
    })
    let option = {
        title: {
            text: '访问次数',
            left: 'center',
            top: 34,
            textStyle: {
                fontSize: 20,
                fontStyle: 'normal',
                color: '#2dfff8',
                lineHeight: 34,
                textShadowColor: 'rgba(102, 157, 224, 1)'
            },
            show: true
        },
        grid: {
            top: 110,
            height: 320
        },
        dataset: {
            source
        },
        legend: {
            show: false
        },
        yAxis: {
            type: 'category',
            inverse: true,
            axisLabel: { color: '#889cb7', interval: 0, fontSize: 14, inside: true, align: 'left', verticalAlign: 'bottom', lineHeight: 32, padding: [0, 0, 0, -8] },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false }
        },
        xAxis: {
            type: 'log',
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false }
        },

        series: [
            {
                name: '访问次数',
                type: 'bar',
                barWidth: 12,
                // barCategoryGap: '60%',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(53, 125, 241, 0.06)'
                },
                label: {
                    normal: {
                        show: true,
                        position: ['100%', '-100%'],
                        align: 'right',
                        color: '#fff',
                        rich: {
                            c: {
                                align: 'center'
                            },
                            r: {
                                align: 'left'
                            }
                        },
                        formatter: function(params) {
                            //标签内容
                            var str = params.data.count
                            return str
                        }
                    }
                },

                itemStyle: {
                    normal: {
                        color: function(params) {
                            //偶数
                            return params.dataIndex % 2 === 0 ? colorEven : colorOdd
                        }
                        // color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        //     {
                        //         offset: 0,
                        //         color: 'rgba(36, 117, 254, 0.1)'
                        //     },
                        //     {
                        //         offset: 1,
                        //         color: '#2dfff8'
                        //     }
                        // ])
                    }
                },

                large: true
            }
        ]
    }
    return option
}
