<template>
    <div class="container">
        <login-modal v-if="openModal" @cancel="openModal = false"></login-modal>
        <div class="wrap wrap-flex">
            <aside v-if="applicationInfoEntity.iconUrl">
                <img :src="applicationInfoEntity.iconUrl" alt="" />
            </aside>
            <main>
                <div class="wrap-title">
                    {{ applicationInfoEntity.applicationName }}
                    <span @click="toggleCollect" style="margin: 0 12px;cursor:pointer">
                        <img :src="applicationInfoEntity.hasCollected ? require('@/assets/index/collected.png') : require('@/assets/index/collect.png')" alt="" style="vertical-align: text-bottom;" />
                    </span>
                </div>
                <div class="info-list">
                    <section class="info-item" v-if="applicationInfoEntity.developer">
                        应用开发者
                        <span>{{ applicationInfoEntity.developer }}</span>
                    </section>
                    <section class="info-item" v-if="applicationInfoEntity.fieldName">
                        所属领域
                        <span>{{ applicationInfoEntity.fieldName }}</span>
                    </section>
                    <section class="info-item" v-if="applicationInfoEntity.createDate">
                        创建时间
                        <span>{{ moment(applicationInfoEntity.createDate).format('YYYY-MM-DD HH:mm:ss') }}</span>
                    </section>
                </div>
                <div class="wrap-title">
                    应用信息
                </div>
                <div class="interface-table">
                    <table>
                        <tr>
                            <td>应用类型</td>
                            <td>{{ showStateLabel(appResultInfo.appResultType, appresult_type_options) }}</td>

                            <td>访问量</td>
                            <td>{{ applicationInfoEntity.visitCount }}次</td>
                        </tr>
                    </table>
                </div>
            </main>
        </div>
        <div class="wrap">
            <div class="wrap-title">
                应用简介
            </div>
            <article>
                {{ applicationInfoEntity.applicationDesc }}
            </article>
        </div>
        <div class="wrap">
            <div class="wrap-title">
                应用下载
            </div>
            <div class="download-list" v-if="appResultInfo.appResultType == '2'">
                <div class="download-item" v-if="appResultInfo.androidQrcode">
                    <span>Android：</span>
                    <section>
                        <img :src="appResultInfo.androidQrcode" alt="" />
                    </section>
                </div>
                <div class="download-item" v-if="appResultInfo.iosQrcode">
                    <span>iOS:</span>
                    <section>
                        <img :src="appResultInfo.iosQrcode" alt="" />
                    </section>
                </div>
            </div>
            <div class="download-list" v-else>
                <span>
                    Web端访问地址：
                    <a-button type="link" @click="openLink(appResultInfo.webUrl)">{{ appResultInfo.webUrl }}</a-button>
                </span>
            </div>
        </div>
        <!-- 关联数据集 -->
        <div class="wrap">
            <div class="wrap-title">
                关联数据集
            </div>
            <div class="data-table">
                <a-table
                    :rowKey="
                        (record, index) => {
                            return index
                        }
                    "
                    :columns="appRelationColumns"
                    :pagination="false"
                    :data-source="appRelationList"
                >
                    <span slot="dataIndex" slot-scope="text, record, index">{{index+1}}</span>
                    <a slot="resName" slot-scope="text, record" @click="goDetail(record)">{{ text }}</a>
                </a-table>
            </div>
        </div>
    </div>
</template>

<script>
import { getApplicationDetail, getAllSelectOptions, collectAppResult, cancelCollectAppResult, getColllectedList } from './api'
import { showStateLabel } from '@/utils/tools.js'
import { getCookie } from '@/utils/cookie'
import LoginModal from '@/components/LoginModal'
import moment from 'moment'
export default {
    data() {
        return {
            openModal: false,
            collectedList: [],
            applicationInfoEntity: {},
            appResultInfo: {},
            appresult_type_options: [],
            KF_USER: getCookie('KF_USER') ? JSON.parse(getCookie('KF_USER')) : {},
            meta: [
                { name: 'ArticleTitle', content: '应用成果详情' },
                { name: 'PubDate', content: moment().format('YYYY-MM-DD') },
                { name: 'ContentSource', content: '舟山市数据开放' }
            ],
            appRelationList:[],
            appRelationColumns:[{
                title: '序号',
                dataIndex: 'dataIndex',
                key: 'dataIndex',
                width:100,
                scopedSlots: { customRender: 'dataIndex' },
            },{
                title: '数据集名称',
                dataIndex: 'resName',
                key: 'resName',
                scopedSlots: { customRender: 'resName' },
                ellipsis: true,
            },{
                title: '数据格式',
                dataIndex: 'type',
                key: 'type',
            },{
                title: '数据提供部门',
                dataIndex: 'officeName',
                key: 'officeName',
                ellipsis: true,
            }]
        }
    },
    components: {
        LoginModal
    },
    created() {
        this.setMenu()
    },
    mounted() {
        this.getAllSelectOptions()
        this.getDetail()
    },
    methods: {
        moment,
        showStateLabel,
        setMenu() {
            for (let item of this.meta) {
                let flag = false
                for (let meta of document.querySelectorAll('meta')) {
                    if (meta.hasAttribute('name') && meta.getAttribute('name') === item.name) {
                        meta.setAttribute('content', item.content)
                        flag = true
                        break
                    }
                }
                if (!flag) {
                    let el = document.createElement('meta')
                    el.setAttribute('name', item.name)
                    el.setAttribute('content', item.content)
                    let firstLink = document.querySelectorAll('head>link')[0]
                    document.getElementsByTagName('head')[0].insertBefore(el, firstLink)
                }
            }
        },
        openLink(link) {
            if (!link) return
            window.open(link)
            return
        },
        changeOpenModal(val) {
            this.openModal = val
        },
        toggleCollect() {
            if (!getCookie('KF_USER')) {
                this.openModal = true
                return
            }
            if (this.applicationInfoEntity.hasCollected) {
                this.cancelCollectAppResult()
                return
            }
            this.collectAppResult()
        },
        collectAppResult() {
            //收藏
            let params = {
                publishTime: moment(this.applicationInfoEntity.createDate).format('YYYY-MM-DD HH:mm:ss'),
                resourceAbstract: this.applicationInfoEntity.applicationDesc,
                resourceId: this.applicationInfoEntity.id,
                resourceName: this.applicationInfoEntity.applicationName,
                resourceProviderId: this.applicationInfoEntity.departmentId,
                resourceProviderName: this.applicationInfoEntity.departmentName,
                resourceUpdateTime: moment(this.applicationInfoEntity.updateDate).format('YYYY-MM-DD HH:mm:ss')
            }
            collectAppResult(params)
                .then(() => {
                    // this.getList()
                    this.$set(this.applicationInfoEntity, 'hasCollected', true)
                    this.$message.success('收藏成功')
                })
                .catch(err => {
                    console.log(err)
                    this.$message.error('收藏失败')
                })
        },
        cancelCollectAppResult() {
            //取消收藏
            let params = {
                id: this.applicationInfoEntity.id,
                resourceType: 3
            }
            cancelCollectAppResult(params)
                .then(() => {
                    // this.getList()
                    this.$set(this.applicationInfoEntity, 'hasCollected', false)
                    this.$message.success('取消收藏成功')
                })
                .catch(err => {
                    console.log(err)
                    this.$message.error('取消收藏失败')
                })
        },
        getAllSelectOptions() {
            let params = {
                types: 'appresult_type'
            }
            getAllSelectOptions(params)
                .then(res => {
                    this.appresult_type_options = res.appresult_type
                })
                .catch(err => {
                    console.log(err)
                })
        },
        handleAppRelation(list){
            this.appRelationList = list || []
        },
        getDetail() {
            Promise.allSettled([this.getColllectedList(), this.getApplicationDetail()])
                .then(res => {
                    // console.log(res)
                    this.collectedList = res[0].status == 'fulfilled' ? res[0].value : []
                    if (res[1].status == 'fulfilled') {
                        this.appResultInfo = res[1].value.appResultInfo || {}
                        this.applicationInfoEntity = res[1].value.applicationInfoEntity || {}
                        let index = this.collectedList.findIndex(el => {
                            return el == this.applicationInfoEntity.id
                        })
                        this.$set(this.applicationInfoEntity, 'hasCollected', index != -1)
                        this.handleAppRelation(res[1].value.appRelation)
                        // this.applicationInfoEntity.hasCollected = index != -1 //hasCollected为true已收藏
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getColllectedList() {
            //获取已收藏列表
            return new Promise((resolve, reject) => {
                if (!Object.prototype.hasOwnProperty.call(this.KF_USER, 'id')) {
                    resolve([])
                    return
                }
                getColllectedList({ id: this.KF_USER.id })
                    .then(res => {
                        resolve(res)
                        // this.collectedList = res
                    })
                    .catch(err => {
                        reject(err)
                        console.log(err)
                    })
            })
        },
        getApplicationDetail() {
            let params = {
                id: this.$route.params.id
            }
            return new Promise((resolve, reject) => {
                getApplicationDetail(params)
                    .then(res => {
                        resolve(res)
                        // this.appResultInfo = res.appResultInfo || {}
                        // this.applicationInfoEntity = res.applicationInfoEntity || {}
                        console.log(res)
                    })
                    .catch(err => {
                        reject(err)
                        console.log(err)
                    })
            })
        },
        goDetail(row){
            this.$router.push({
                name: 'InterfaceServiceDataSetDetail',
                query: {
                    id: row.resId
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
.container {
    width: 1200px;
    margin: 0 auto;
    padding: 36px 0 16px 0;
    .wrap {
        position: relative;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        padding: 20px;
        margin-bottom: 20px;
        .wrap-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            /* line-height: 9px; */
            letter-spacing: 0px;
            color: #303343;
            margin-bottom: 22px;
        }
        > aside {
            width: 290px;
            height: 207px;
            flex-shrink: 0;
            background-color: #fff;
            margin-right: 20px;
            overflow: hidden;
            img {
                max-width: 100%;
            }
        }
        > main {
            .info-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 18px;
                margin-bottom: 26px;
                .info-item {
                    position: relative;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    /* line-height: 9px; */
                    letter-spacing: 0px;
                    color: #8e92a1;
                    padding: 4px 16px;
                    &:not(:first-of-type) {
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 1px;
                            height: 18px;
                            background: #303343;
                        }
                    }
                    &:first-of-type {
                        padding-left: 0;
                    }
                    > span {
                        padding-left: 8px;
                        color: #303343;
                    }
                }
            }
            .interface-table {
                width: 100%;
                table {
                    width: 100%;
                    table-layout: fixed;
                    background-color: #ffffff;
                    tr {
                        td {
                            font-size: $fs12;
                            padding: 14px 12px;
                            border: 1px solid #dbdbdb;
                            color: #000025;
                            &:nth-of-type(odd) {
                                width: 90px;
                                text-align: right;
                                color: #8e92a1;
                                background-color: #f9f9f9;
                            }
                        }
                    }
                }
            }
        }
        > article {
            font-size: $fs14;
            line-height: 24px;
            letter-spacing: 0px;
            color: #303343;
            text-indent: $fs14 * 2;
            padding-bottom: 12px;
        }
    }
    .download-list {
        display: flex;
        align-items: center;
        .download-item {
            display: flex;
            align-items: center;
            margin-right: 74px;
            span {
                padding-right: 14px;
            }
            section {
                position: relative;
                flex-shrink: 0;
                width: 120px;
                height: 120px;
                border: 1px solid #ddd;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 100%;
                }
            }
        }
    }
    /deep/ button.ant-btn {
        width: auto;
        color: #1890ff;
    }
    .wrap-flex {
        display: flex;
        align-content: center;
    }
}
</style>
