<template>
    <div class="center-content">
        <login-modal v-if="openModal" @cancel="openModal = false"></login-modal>
        <div class="left-content">
            <!-- 左侧筛选 -->
            <div class="main">
                <div class="main-header">
                    <TabBar :list="typeTabs" @switchTab="switchTypeTab" class="order-tab"></TabBar>
                </div>
                <!-- <div class="slide-list ">
                    <ul id="org-list" class="diy-scroll">
                        <li @click="handleOrgClick('')" :class="[conditions.officeCode == '' ? 'active' : '', 'all']">
                            <div class="flex-box">
                                <span class="title">全部单位</span>
                                <span>({{ departmentInterfaceCount }})</span>
                            </div>
                        </li>
                        <li @click="handleOrgClick(item.officeCode)" v-for="item in departmentArr.slice(0, 8)" :key="item.officeCode + '1'" :class="[conditions.officeCode == item.officeCode ? 'active' : '']">
                            <div class="flex-box">
                                <span class="title" v-if="item.officeCode">{{ item.officeName }}</span>
                                <span>({{ item.interfaceCount }})</span>
                            </div>
                        </li>
                        <template v-if="hasShowAll">
                            <li @click="handleOrgClick(item.officeCode)" v-for="item in departmentArr.slice(8)" :key="item.officeCode + '1'" :class="[conditions.officeCode == item.officeCode ? 'active' : '']">
                                <div class="flex-box">
                                    <span class="title" v-if="item.officeCode">{{ item.officeName }}</span>
                                    <span>({{ item.interfaceCount }})</span>
                                </div>
                            </li>
                        </template>
                    </ul>
                    <div v-if="departmentArr.length > 8" @click="hasShowAll = !hasShowAll" style="cursor:pointer">{{ hasShowAll ? '收起' : '展开' }}隐藏项</div>
                </div> -->
                <div class="dept-tree-list">
                    <div class="dept-tree-item" v-for="(item,index) in deptTreeList" :key="index">
                        <div class="dept-tree-item-wrap">
                            <div class="dept-tree-name" :class="{active:!conditions.officeCode && conditions.areaCode == item.areaCode}" @click.stop="onClickParentDept(item)">
                                <div v-if="item.child && item.child.length" class="icon-caret">
                                    <a-icon type="caret-right" v-if="!item.expanded" />
                                    <a-icon type="caret-down" v-else />
                                </div>
                                <p class="name" :title="item.areaName">{{item.areaName}}</p>
                                <span class="score">({{item.count}})</span>
                            </div>
                            <template v-if="item.child && item.child.length">
                                <div class="sub-list" v-show="item.expanded">
                                    <div class="sub-item" v-for="sub in item.child" :key="sub.deptId" :class="{active:conditions.officeCode && conditions.officeCode == sub.deptId}" @click="onClickChildDept(sub)">
                                        <div class="sub-item-wrap">
                                            <p class="sub-item-name" :title="sub.deptName">{{sub.deptName}}</p>
                                            <span class="sub-item-score">({{sub.count}})</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-content">
            <main class="main">
                <div class="main-header">
                    <TabBar :list="orderTabs" @switchTab="switchOrderTab" class="order-tab"></TabBar>
                    <a-input-search class="search-input" placeholder="搜索接口名称" style="width: 200px" @search="onSearch" @pressEnter="onPressEnter" v-model.trim="conditions.keywords" />
                </div>
                <a-spin :spinning="loading" tip="数据加载中...">
                    <div class="main-result-tips">
                        <p>
                            为你找到
                            <span>{{ pageOptions.total }}</span>
                            个接口
                        </p>
                        <div class="clear" @click="clearConditions">清空条件</div>
                    </div>
                    <template v-if="dataList.length">
                        <div class="main-list">
                            <StarList :list="dataList" :starList="collectedList" @starClick="handleStarClick" @titleClick="goDetail">
                                <template v-slot:rightBtn="{ item }">
                                    <template v-if="getCookie('KF_USER')">
                                        <div class="btn-add" :class="item.hasApplied ? 'btn-added' : ''" @click="goApply(item)">
                                            {{ item.hasApplied ? '已申请' : '申请' }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="btn-add" @click="goApply(item)">
                                            申请
                                        </div>
                                    </template>
                                </template>
                            </StarList>
                            <div class="pagination">
                                <a-pagination :pageSizeOptions="['10', '20', '50']" show-size-changer show-quick-jumper :defaultPageSize="pageOptions.pageSize" :current="pageOptions.pageNo" :total="pageOptions.total" @showSizeChange="handlePageSizeChange" @change="handlePageChange" />
                            </div>
                        </div>
                    </template>
                    <NoData v-else></NoData>
                </a-spin>
            </main>
        </div>
    </div>
</template>

<script>
import NoData from '@/components/NoData.vue'
import StarList from '@/components/StarList.vue'
import TabBar from './components/TabBar'
import { getInterCountByOffice, getInterfaceCollectedList, getDataInerfaceList, collectInterface, cancelCollectInterface, getDepartmentList, getRelateAppList, getInterfaceApplied, getHasAppliedInterfaces } from './api'
import { getCookie } from '@/utils/cookie'
import LoginModal from '@/components/LoginModal'
const defaultConditions = {
    keywords: '', //关键词
    order: 'onlineTime',
    officeCode: null,
    areaCode:null
}
const defaultPageOptions = {
    pageNo: 1, //页码
    pageSize: 10, //每页条数
    total: 0
} //为了清空条件
export default {
    name: 'InterfaceList',
    components: {
        NoData,
        StarList,
        TabBar,
        LoginModal
    },
    data() {
        return {
            relateAppList: [], //我的关联应用列表
            hasShowAll: false, //已经展示全部
            openModal: false,
            departmentArr: [],
            departmentInterfaceCount: 0,
            orgHide: true,
            loading: true, //加载中
            conditions: { ...defaultConditions }, //搜索条件
            pageOptions: { ...defaultPageOptions },
            orderTabs: [
                { value: 'onlineTime', label: '发布时间', active: true },
                { value: 'visitCount', label: '访问次数', active: false },
                { value: 'useCount', label: '调用次数', active: false }
            ], //筛选内容数组
            typeTabs: [{ value: '0', label: '数据来源单位', active: true }],
            dataList: [], //数组列表
            collectedList: [],
            hasAppliedInterfacesList: [],
            KF_USER: getCookie('KF_USER') ? JSON.parse(getCookie('KF_USER')) : {},
            deptTreeList:[],
        }
    },
    watch:{
        '$store.state.areaCode':{
            handler:function(val){
                console.log('val');
                this.initDept()
                this.filterListByDept()
            }
        }
    },
    methods: {
        getCookie,
        getRelateAppList() {
            let params = {
                param: {
                    applicationState: 3
                }
            }
            getRelateAppList(params)
                .then(res => {
                    // console.log('getRelateAppList=>', res)
                    this.relateAppList = res.records || []
                })
                .catch(err => {
                    console.log(err)
                })
        },
        changeOpenModal(val) {
            this.openModal = val
        },
        handleOrgClick(item) {
            this.pageOptions.pageNo = 1
            this.conditions.officeCode = item
            this.getInit()
        },
        getDepartmentList() {
            //获取部门列表和对应包含的接口数
            getDepartmentList()
                .then(res => {
                    this.departmentArr = res
                    this.departmentInterfaceCount = 0
                    this.departmentArr.map(item => {
                        this.departmentInterfaceCount = this.departmentInterfaceCount + item.interfaceCount
                        return item
                    })
                    console.log(this.departmentArr)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        hideInit() {
            // 获取左侧列表的高度设置是否隐藏
            let orgHeight = document.getElementById('org-list').getBoundingClientRect().height
            if (orgHeight > 240) {
                this.orgHide = true
            } else {
                this.orgHide = false
            }
        },
        showGoAddAppConfirm() {
            let _this = this
            this.$confirm({
                class: 'goAddAppConfirm',
                title: '您当前没有审核通过的应用，不可进行接口申请。',
                closable: true,
                cancelText: '确定',
                cancelType: 'primary',
                okText: '前往应用注册',
                okType: 'link',
                maskClosable: true,
                onOk() {
                    _this.$router.push({
                        name: 'MyAppList'
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        goApply(item) {
            //申请
            if (!getCookie('KF_USER')) {
                //未登录弹出提示登录框
                this.openModal = true
                return
            }
            if (item.hasApplied) return //已登录，点击的是已申请过的啥也不处理
            if (!this.relateAppList.length) {
                //已登录，未申请接口，但是该账号未关联应用，弹出提示去关联应用提示框随后跳到新增关联应用页面
                this.showGoAddAppConfirm()
                return
            }
            if (item.type == '0') {
                //0表示第三方注册接口直接跳
                this.$router.push({
                    name: 'InterfaceServiceApplication',
                    params: {
                        id: item.id
                    }
                })
                return
            }
            getInterfaceApplied({ interfaceId: item.id })
                .then(res => {
                    // debugger
                    if (res.open) {
                        if (res.serviceId) {
                            this.$router.push({
                                name: 'InterfaceServiceEdit',
                                params: {
                                    id: res.serviceId
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'InterfaceServiceApplication',
                                params: {
                                    id: item.id
                                }
                            })
                        }

                        return
                    }
                    if (res.catalogHasApplied) {
                        if (res.serviceId) {
                            this.$router.push({
                                name: 'InterfaceServiceEdit',
                                params: {
                                    id: item.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'InterfaceServiceApplication',
                                params: {
                                    id: item.id
                                }
                            })
                        }
                        return
                    }
                    this.$message.warn('该接口为受限接口，请先申请对应的数据目录')
                })
                .catch(err => {})
            //进入申请页面
        },
        getInterfaceCollectedList() {
            //收藏列表
            if (!Object.prototype.hasOwnProperty.call(this.KF_USER, 'id')) {
                this.collectedList = []
                return
            }
            getInterfaceCollectedList({ id: this.KF_USER.id })
                .then(res => {
                    this.collectedList = res
                    console.log('getInterfaceCollectedList=>', res)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        onSearch(value) {
            this.pageOptions.pageNo = 1 //切换页数
            this.getInit()
        },
        onPressEnter(e) {
            this.pageOptions.pageNo = 1
            this.conditions.keywords = e.target.value
            this.getInit()
        },
        onKeywordsChange(val) {
            // console.log(val)
            this.conditions.keywords = val
        },
        handleSearchEnter(text) {
            this.conditions.keywords = text
            this.getInit()
        },
        handleStarClick(item, state) {
            console.log(item, state)
            //点击收藏按钮
            if (!getCookie('KF_USER')) {
                this.openModal = true
                return
            }
            if (state) {
                // 取消收藏
                this.cancelCollectInterface(item)
            } else {
                // 收藏
                this.collectInterface(item)
            }
        },
        collectInterface(item) {
            // debugger
            let params = {
                resourceId: item.id, //id
                resourceName: item.name,
                publishTime: item.onlineTime,
                resourceAbstract: item.remarks, //资源摘要
                resourceProviderId: item.officeCode || '',
                resourceProviderName: item.officeName,
                resourceUpdateTime: item.updateDate || '',
                isstructuredData: 1,
                resourceType: 1
            }
            collectInterface(params)
                .then(() => {
                    this.getInterfaceCollectedList()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        cancelCollectInterface(item) {
            let params = {
                id: item.id,
                resourceType: 2
            }
            cancelCollectInterface(params)
                .then(() => {
                    this.getInterfaceCollectedList()
                })
                .catch(() => {
                    this.$message('取消失败')
                })
        },
        switchTypeTab(value) {
            //点击筛选切换
            this.pageOptions.pageNo = 1
            if (this._.find(this.typeTabs, { active: true }).value == value) return
            this.typeTabs = this.typeTabs.map(item => {
                item.active = item.value == value ? true : false
                return item
            })
        },
        switchOrderTab(value) {
            //点击筛选切换
            this.pageOptions.pageNo = 1
            if (this._.find(this.orderTabs, { active: true }).value == value) return
            this.orderTabs = this.orderTabs.map(item => {
                item.active = item.value == value ? true : false
                return item
            })
            this.conditions.order = value
            this.getInit()
        },
        handlePageChange(current, size) {
            //分页页码切换触发事件
            this.pageOptions.pageNo = current //切换页数
            this.pageOptions.pageSize = size
            this.getInit()
        },
        handlePageSizeChange(current, size) {
            this.pageOptions.pageNo = 1 //切换页数
            this.pageOptions.pageSize = size
            this.getInit()
        },
        goDetail(item) {
            //跳转至详情页面
            if (item.type == '1') {
                this.$router.push({
                    path: 'DataSetDetail',
                    query: { id: item.id }
                })
                return
            }
            this.$router.push({
                path: '/InterfaceService/Detail',
                query: { id: item.id, serviceState: item.serviceState }
            })
        },
        getInit() {
            let interfaceListParams = {
                pageNo: this.pageOptions.pageNo,
                pageSize: this.pageOptions.pageSize,
                param: {
                    // businessCode: 1,//业务类型
                    desc: 'desc',
                    name: this.conditions.keywords,
                    officeCode: this.conditions.officeCode, //部门编码
                    order: this.conditions.order,
                    areaCode:this.conditions.areaCode
                    // userOffice:'',//当前用户所属部门id
                }
            }
            this.loading = true
            Promise.allSettled([getHasAppliedInterfaces(), getDataInerfaceList(interfaceListParams)])
                .then(res => {
                    // debugger
                    let getHasAppliedInterfacesRes = res[0]
                    let getDataInerfaceListRes = res[1]
                    this.handleHasAppliedInterfacesRes(getHasAppliedInterfacesRes)
                    this.handleGetDataInerfaceListRes(getDataInerfaceListRes)
                    // debugger
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        handleHasAppliedInterfacesRes(res) {
            //处理我的已申请接口列表
            if (res.status == 'rejected') {
                this.hasAppliedInterfacesList = []
                return
            }
            this.hasAppliedInterfacesList = res.value
        },
        handleGetDataInerfaceListRes(res) {
            //处理获取数据接口列表
            let _this = this
            if (res.status == 'rejected') {
                this.dataList = []
                return
            }
            this.pageOptions.total = res.value.total
            // debugger
            this.dataList = []
            res.value.records.forEach(item => {
                item.hasApplied = (_this.hasAppliedInterfacesList || []).includes(item.id)
                _this.dataList.push(item)
            })
        },
        clearConditions() {
            //清空条件
            this.conditions = { ...defaultConditions }
            this.pageOptions = { ...defaultPageOptions }
            this.orderTabs = this.orderTabs.map(item => {
                item.active = item.value == 'onlineTime' ? true : false
                return item
            })
            this.getInit()
        },
        getInterCountByOffice(){
            return new Promise((resolve,reject)=>{
                getInterCountByOffice().then(res=>{
                    if(res){
                        this.deptTreeList = res.map(item=>{
                            item.expanded = false
                            return item
                        })
                        console.log('deptTreeList=>',this.deptTreeList);
                        resolve()
                    }
                    reject()
                }).catch(()=>{
                    reject()
                })
            })
            
        },
        onClickParentDept(row){
            // 判断activeDeptRow的areaCode是否和row.areaCode相等，相等的话直接return
            row.expanded = !row.expanded
            this.conditions.areaCode = row.areaCode
            this.conditions.officeCode = row.deptId
            this.filterListByDept()
        },
        onClickChildDept(row){
            this.conditions.areaCode = null
            this.conditions.officeCode = row.deptId
            this.filterListByDept()
        },
        filterListByDept(){
            this.pageOptions.pageNo = 1
            this.getInit()
        },
        initDept(){
            this.conditions.areaCode = this.$store.state.areaCode
            this.conditions.officeCode = null
            this.deptTreeList.map(item=>{
                if(item.areaCode == this.conditions.areaCode){
                    item.expanded = true
                }
                return item
            })
        },
    },
    // mounted() {
    //     this.getInit()
    //     this.getRelateAppList()
    //     this.getDepartmentList()
    //     this.getInterfaceCollectedList()
    //     // this.getDataInerfaceList()
    // },
    async activated() {
        console.log('activated');
        await this.getInterCountByOffice()
        this.initDept()
        this.getRelateAppList()
        // this.getDepartmentList()
        this.getInterfaceCollectedList()
        this.getInit()
    }
}
</script>

<style scoped lang="scss">
.center-content {
    padding-top: 24px;
    padding-bottom: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .left-content {
        flex-shrink: 0;
        width: 265px;
        .main {
            .main-header {
                height: 64px;
                display: flex;
                flex-flow: row nowrap;
                border-bottom: 1px solid #e7e7e7;
            }
            .order-tab {
                align-self: flex-end;
            }
            .slide-list.height-auto {
                height: auto;
            }
            .slide-list.height-hide {
                overflow: hidden;
                height: 240px;
            }
            .slide-list {
                margin-top: 10px;
                ul {
                    max-height: 480px;
                    overflow: auto;
                }
                .title {
                    margin-left: 10px;
                    color: #333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 32px;
                }
                .flex-box {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
                li {
                    display: flex;
                    padding: 8px 27px 8px 12px;
                    align-items: center;
                    height: 30px;
                    font-size: $fs12;
                    cursor: pointer;
                    span {
                        color: #999;
                        font-size: $fs12;
                    }
                }
                li:hover {
                    background-color: #357df1;
                    span {
                        color: #fff;
                    }
                }
                li.active {
                    background-color: #357df1;
                    span {
                        color: #fff;
                    }
                }
            }
            .dept-tree-list{
                margin-top: 10px;
                background: #f2f5f9;
                .dept-tree-item{
                    border-bottom: 1px solid #d5d5d5;
                    font-size: 12px;
                    .dept-tree-item-wrap{
                        .dept-tree-name{
                            height: 50px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            padding: 0 10px 0 24px;
                            position: relative;
                            .icon-caret{
                                position: absolute;
                                left: 8px;
                                top: 50%;
                                transform: translateY(-50%);
                                color: #999;
                            }
                            .name{
                                font-weight: bold;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .score{
                                margin-left: auto;
                                flex-shrink: 0;
                                color: #999;
                            }
                            &.active{
                                background: #357df1;
                                color: #fff;
                                .score,
                                .icon-caret{
                                    color: #fff;
                                }
                            }
                        }
                        .sub-list{
                            max-height: 690px;
                            overflow-y: auto;
                            .sub-item{
                                padding: 0 10px 0 24px;
                                .sub-item-wrap{
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                    .sub-item-name{
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    .sub-item-score{
                                        margin-left: auto;
                                        flex-shrink: 0;
                                        color: #999;
                                    }
                                }
                                &.active{
                                    background: #357df1;
                                    color: #fff;
                                    .sub-item-wrap{
                                        .sub-item-score{
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .right-content {
        /* flex: 1; */
        margin-left: 20px;
        /* padding: 0 32px 0 52px; */
        background: #fff;
        min-height: 800px;
        width: 900px;
        .main {
            .main-header {
                height: 64px;
                display: flex;
                flex-flow: row nowrap;
                border-bottom: 1px solid #e7e7e7;
                .order-tab {
                    align-self: flex-end;
                }
                .search-input {
                    align-self: center;
                    margin-left: auto;
                }
            }
            .main-result-tips {
                position: relative;
                margin-top: 28px;
                p {
                    font-weight: bold;
                    font-size: $fs20;
                    color: #333;
                    span {
                        color: #357df1;
                    }
                }
                .clear {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 12px 0;
                    font-size: $fs12;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
        .pagination {
            text-align: center;
            margin-top: 40px;
        }
    }
    /deep/ .btn-add {
        border-radius: 14px !important;
        padding: 0 !important;
        height: 24px !important;
        line-height: 24px !important;
        width: 68px !important;
    }
}
</style>
<style lang="scss">
.goAddAppConfirm {
    position: relative;
    /deep/ .ant-modal-body {
        padding: 60px 80px 24px !important;
    }
    /deep/ .ant-modal-confirm-btns {
        .ant-btn {
            &:first-of-type {
                background: #357df1;
                color: #fff;
                border: 1px solid #357df1;
            }
            &:last-of-type {
                color: #357df1;
                background: transparent;
            }
        }
    }
}
</style>
