<template>
    <div class="container">
        <a-breadcrumb>
            <a-breadcrumb-item v-for="item in levelList" :key="item.path">
                <router-link :to="item.redirect || item.path" class="no-redirect" style="cursor: pointer;">{{ item.meta.name }}</router-link>
            </a-breadcrumb-item>
        </a-breadcrumb>
        <main class="main">
            <header class="headbox">
                <div class="headbox-head">{{ article.title }}</div>

                <div class="headbox-headinfo">
                    <section>
                        <span>发布时间：{{ article.publishtime }}</span>
                        <span>浏览次数：{{ article.visitCount }}</span>
                        <span>来源：{{ article.source }}</span>
                    </section>
                    <div class="share-box">
                        <span>分享到：</span>
                        <div class="share-main">
                            <a-popover placement="top">
                                <template slot="content">
                                    <div class="qrcode-box" style="width:200px;height:200px;">
                                        <div id="qrcode" ref="qrcode"></div>
                                    </div>
                                </template>
                                <section id="share-wechat" @mousemove="initQrCode"><img src="@/assets/wechat.png" /></section>
                            </a-popover>
                            <section @click.stop="shareToWeibo"><img src="@/assets/weibo.png" /></section>
                            <section @click.stop="shareToQzone"><img src="@/assets/qzone.png" /></section>
                        </div>
                    </div>
                </div>
            </header>
            <div class="content">
                <div v-if="article.picture" class="content-pic">
                    <img :src="article.picture" alt="" srcset="" />
                </div>
                <article class="content-txt" v-html="article.content"></article>
                <div v-if="article.file" class="content-file">
                    政策法规文件：
                    <span @click="downLoadFile">{{ article.file }}</span>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { GetDetail } from './api/index'
import { handleDateToString } from '@/utils/tools'
import QRCode from 'qrcodejs2'
export default {
    data() {
        return {
            lastBread: '',
            id: '',
            article: '暂无数据',
            levelList: null,
            loadQrCode: true
        }
    },
    created() {
        let id = this.$route.params.id
        this.lastBread = this.$route.params.breadTitl
        this.handleMeta()
        this.getBreadcrumb()
        this.getDetail(id)
    },
    methods: {
        initQrCode() {
            if (!this.loadQrCode) return
            this.loadQrCode = false
            setTimeout(() => {
                let qrcode = new QRCode('qrcode', {
                    width: 200,
                    height: 200,
                    text: window.location.href
                })
            }, 300)
        },
        shareToWeibo() {
            window.open(`http://service.weibo.com/share/share.php?url=${encodeURIComponent(window.location.href)}&title=${this.article.title}&sharesource=weibo&pic=${this.article.picture || ''}&appkey=`)
        },
        shareToQzone() {
            window.open(`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(window.location.href)}&title=${this.article.title}&pics=${this.article.picture || ''}&summary=${this.article.abstractContent || ''}&sharesource=qzone`)
        },
        handleMeta() {
            if (!this.$route.params.id) {
                return
            }
            let metaInfo = [
                {
                    name: 'ArticleTitle',
                    content: this.$route.params.title
                },
                {
                    name: 'PubDate',
                    content: handleDateToString(this.$route.params.publishtime)
                },
                {
                    name: 'ContentSource',
                    content: this.$route.params.source
                }
            ]
            this.$nextTick(() => {
                for (let item of metaInfo) {
                    let flag = false
                    for (let meta of document.querySelectorAll('meta')) {
                        if (meta.hasAttribute('name') && meta.getAttribute('name') === item.name) {
                            meta.setAttribute('content', item.content)
                            flag = true
                            break
                        }
                    }
                    if (!flag) {
                        let el = document.createElement('meta')
                        el.setAttribute('name', item.name)
                        el.setAttribute('content', item.content)
                        document.getElementsByTagName('head')[0].appendChild(el)
                    }
                }
            })
        },
        removeMeta() {
            let metaNames = ['ArticleTitle', 'PubDate', 'ContentSource']
            for (let meta of document.querySelectorAll('meta')) {
                if (meta.hasAttribute('name') && metaNames.includes(meta.getAttribute('name'))) {
                    document.getElementsByTagName('head')[0].removeChild(meta)
                }
            }
        },
        getDetail(id) {
            GetDetail({ id: id }).then(res => {
                this.article = res
                this.article.publishtime = handleDateToString(this.article.publishtime, false)
            })
        },
        downLoadFile() {
            window.open(this.article.file)
        },
        getBreadcrumb() {
            let matched = this.$route.matched.filter(item => item.name)
            const first = matched[0]
            matched[matched.length - 1].meta.name = this.lastBread
            if (first && (first.name !== '首页' || first.path !== '')) {
                matched = [
                    {
                        name: '首页',
                        path: '/Home',
                        meta: {
                            requiresAuth: false,
                            name: '首页'
                        }
                    }
                ].concat(matched)
            }
            this.levelList = matched
        }
    },
    beforeDestroy() {
        this.removeMeta()
    },
    watch: {
        $route() {
            this.getBreadcrumb()
        }
    }
}
</script>
<style lang="scss">
.bsBox {
    box-sizing: content-box;
}
</style>
<style lang="scss" scoped>
.container {
    width: 1200px;
    height: 100%;
    min-height: 927px;
    position: relative;
    margin: 0 auto;
    padding: 33px 0 48px;
    font-family: Source Han Sans CN;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0;
    // display: flex;
    // justify-content: flex-start;
    // align-items: flex-start;
    .content-breadcrumb {
        margin-bottom: 80px;
    }
    .main {
        .headbox {
            .headbox-head {
                height: 48px;
                text-align: center;
                font-size: 28px;
                line-height: 48px;
                letter-spacing: 2px;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 32px;
            }
            .headbox-headinfo {
                position: relative;
                height: 16px;
                text-align: center;
                line-height: 16px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.45);
                margin-bottom: 32px;
                & > section {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 100px;
                    span {
                        margin-right: 20px;
                    }
                }

                .share-box {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;
                    & > span {
                        font-size: 12px;
                    }
                    .share-main {
                        display: flex;
                        align-items: center;
                        & > section {
                            flex-shrink: 0;
                            width: 24px;
                            padding: 0 4px;
                            cursor: pointer;
                            img {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .content {
            .content-pic {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
            .content-txt {
                font-size: 18px;
                line-height: 28px;
                color: rgba(0, 0, 0, 0.65);
            }
            .content-file {
                span {
                    color: #409eff;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
