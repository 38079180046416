import '../map/world-china'
export default list => {
    var geoCoordMap = new Object()
    var data = new Array()
    var maxValue = 0
    for (let item of list) {
        maxValue = item.visitNum > maxValue ? item.visitNum : maxValue
        geoCoordMap[item.name] = [item.longitude, item.latitude]
        data.push({
            name: item.name,
            value: item.visitNum
        })
    }
    var convertData = function(data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name]
            if (geoCoord) {
                res.push({
                    name: data[i].name,
                    value: geoCoord.concat(data[i].value)
                })
            }
        }
        return res
    }

    var convertedData = [
        convertData(data),
        convertData(
            data
                .sort(function(a, b) {
                    return b.value - a.value
                })
                .slice(0, 6)
        )
    ]
    let option = {
        title: {
            text: '访问来源追溯',
            left: 'center',
            top: 34,
            textStyle: {
                fontSize: 20,
                fontStyle: 'normal',
                color: 'rgba(45, 255, 248, 1)',
                lineHeight: 34,
                textShadowColor: 'rgba(102, 157, 224, 1)'
            },
            subtextStyle: {
                fontSize: 20
            },
            show: false
        },
        // grid: {
        //     backgroundColor: '#05162d'
        // },
        geo: {
            center: [114.31, 30.52], //视角的中心点为上海
            roam: true, //缩放平移
            map: 'world',
            itemStyle: {
                normal: {
                    areaColor: '#164581',
                    borderColor: '#2c5689',
                    borderWidth: 1
                    // opacity: '.8'
                },
                emphasis: {
                    areaColor: '#164581',
                    borderColor: '#2c5689',
                    opacity: '.8'
                }
            },
            zoom: 12,
            scaleLimit: {
                //所属组件的z分层，z值小的图形会被z值大的图形覆盖
                min: 4, //最小的缩放值
                max: 60 //最大的缩放值
            },
            nameMap: {
                China: '中国'
            },
            regions: [],
            emphasis: {
                itemStyle: {
                    areaColor: '#123555',
                    shadowColor: '#000',
                    shadowBlur: 10
                },
                label: {
                    show: false
                }
            }
        },
        tooltip: {
            show: true
        },
        series: [
            {
                name: '散点',
                type: 'effectScatter',
                coordinateSystem: 'geo',
                data: convertedData[0],
                rippleEffect: {
                    scale: 4.5,
                    period: 8
                },
                symbolSize: function(val) {
                    let v = (val[2] / maxValue).toFixed(1)
                    if (v >= 0.8) {
                        v = 1
                    } else if (v <= 0.3) {
                        v = 0.3
                    }
                    return 15 * v
                },
                label: {
                    normal: {
                        formatter: '{b}',
                        position: 'right',
                        show: false
                    },
                    emphasis: {
                        formatter: '{b}',
                        show: false
                    }
                },
                itemStyle: {
                    normal: {
                        color: '#00fff7'
                    }
                },
                tooltip: {
                    show: true,
                    trigger: 'item',
                    formatter: function(params) {
                        return params.name + ' : ' + params.value[2]
                    }
                }
            }
        ]
    }
    return option
}
