<template>
    <div class="container">
        <div class="wrap wrap-flex">
            <main>
                <div class="wrap-title">网站地图</div>
                <div class="nav-list">
                    <div class="nav-item" v-for="item in routes" :key="item.key">
                        <p @click="navTo(item.key)">{{ item.name }}</p>
                        <ul class="nav-item-child">
                            <li class="child" v-for="sub in item.children" :key="sub.key" @click.stop="navTo(sub.key)">
                                <span>{{ sub.name }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { getCommunication } from '@/views/InteractiveCommunication/api'
export default {
    data() {
        return {
            routes: [
                {
                    name: '开放数据',
                    path: '/OpenData',
                    key: 'OpenData',
                    children: [
                        {
                            name: '数据集',
                            path: 'DataSet',
                            key: 'DataSet'
                        },
                        {
                            name: '数据目录',
                            path: 'DataCatalog',
                            key: 'DataCatalog'
                        }
                    ]
                },
                {
                    name: '接口服务',
                    path: '/InterfaceService',
                    key: 'InterfaceService'
                },
                {
                    name: '应用成果',
                    path: 'ApplicationResults',
                    key: 'ApplicationResults'
                },
                {
                    name: '地图服务',
                    path: 'MapService',
                    key: 'MapService'
                },
                {
                    name: '开发者中心',
                    path: 'DeveloperCenter',
                    key: 'DeveloperCenter'
                },
                {
                    name: '开放指数',
                    path: 'OpenIndex',
                    key: 'OpenIndex'
                },
                {
                    name: '互动交流',
                    path: 'InteractiveCommunication',
                    key: 'InteractiveCommunication'
                },
                {
                    name: '政策动态',
                    path: 'PolicyTrends',
                    key: 'PolicyTrends'
                }
            ]
        }
    },
    components: {},
    created() {},
    mounted() {},
    methods: {
        async navTo(name) {
            // 地图
            let _this = this
            if (name == 'MapService') {
                window.open(`https://ditu.amap.com/search?query=${this.city.mapName}`, '_blank')
                return
            }
            // if (name == 'OpenIndex') {
            //     const { href } = _this.$router.resolve({ name })
            //     window.open(href, '_blank')
            //     return
            // }
            let routerNow = this.$route.name
            if (routerNow == name) {
                return false
            }
            this.$router.push({ name })

            // 沟通交流
        }
    }
}
</script>

<style scoped lang="scss">
.container {
    width: 1200px;
    margin: 0 auto;
    padding: 36px 0 16px 0;
    .contact-us-block {
        h2 {
            font-size: 16px;
            font-weight: bold;
        }
        p {
            font-size: 16px;
            padding: 4px 0;
        }
    }
    .wrap {
        position: relative;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        padding: 20px;
        margin-bottom: 20px;
        .wrap-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            /* line-height: 9px; */
            letter-spacing: 0px;
            color: #303343;
            margin-bottom: 22px;
        }
        .nav-list {
            
            .nav-item {
                    padding: 6px 14px;
                    border-bottom: 1px solid #e3e3e3;
                    &:last-of-type{
                        border-bottom: 1px solid transparent;
                    }
                & > p {
                    cursor: pointer;
                    font-size: 18px;
                    color: #357df1;
                    // font-weight: bold;
                }
                .nav-item-child {
                        display: flex;
                        align-items: center;
                        
                    .child {
                            padding: 4px 0;
                            margin-left: 20px;
                            font-size: 14px;
                        & > span {
                            color: #666;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        > main {
            width: 100%;
        }
    }

    /deep/ button.ant-btn {
        width: auto;
        color: #1890ff;
    }
    .wrap-flex {
        display: flex;
        align-content: center;
    }
}
</style>
