<template>
    <div class="center-content container">
        <login-modal v-if="openModal" @cancel="openModal = false"></login-modal>
        <div class="wrap">
            <header class="check-area" v-if="fieldOptions.length">
                <p class="check-title">
                    应用成果
                </p>
                <div class="check-list">
                    <a-radio-group v-model="conditions.fieldId" @change="onSelectFieldChange">
                        <a-radio value="" class="check-item">
                            全部
                        </a-radio>
                        <a-radio v-for="item in fieldOptions" :key="item.id" :value="item.id" class="check-item">
                            {{ item.name }}
                        </a-radio>
                    </a-radio-group>
                </div>
            </header>
            <div class="content">
                <main class="main">
                    <div class="main-header">
                        <TabBar :list="orderTabs" @switchTab="switchOrderTab" class="order-tab"></TabBar>
                    </div>
                    <div class="main-content">
                        <a-spin :spinning="loading" tip="数据加载中...">
                            <template v-if="list.length">
                                <p class="main-result">
                                    为你找到
                                    <span>{{ pageOptions.total }}个</span>
                                    应用成果
                                </p>
                                <a-row :gutter="[16, 16]" class="card-list">
                                    <a-col :span="6" v-for="item in list" :key="item.id">
                                        <section class="card-item">
                                            <div class="item-img" @click="goDetail(item.id)">
                                                <img :src="item.iconUrl ? item.iconUrl : require('@/assets/index/app-default.png')" :alt="item.applicationName" />
                                            </div>
                                            <div class="item-info-box">
                                                <div class="collect-icon" @click="toggleCollect(item)">
                                                    <img :src="item.hasCollected ? require('@/assets/index/collected.png') : require('@/assets/index/collect.png')" alt="" />
                                                </div>
                                                <div class="item-info-top">
                                                    <p class="item-title" @click="goDetail(item.id)">{{ item.applicationName }}</p>
                                                    <span class="item-field">
                                                        <template v-if="item.fieldName">
                                                            {{ item.fieldName }}
                                                            <br />
                                                        </template>
                                                        <template v-if="item.createDate">
                                                            {{ moment(item.updateDate).format('YYYY-MM-DD HH:mm:ss') }}
                                                        </template>
                                                    </span>
                                                    <div class="icon-box">
                                                        <section class="item-iconinfo">
                                                            <img :src="require('@/assets/index/浏览icon.png')" alt="" />
                                                            {{ item.visitCount }}
                                                        </section>

                                                        <section class="item-iconinfo" style="margin-left:auto;padding-right:0">
                                                            <img :src="require('@/assets/index/下载icon.png')" alt="" />
                                                            0
                                                        </section>
                                                    </div>
                                                </div>
                                                <footer v-if="item.departmentName">
                                                    <section class="item-iconinfo">
                                                        <img :src="require('@/assets/index/公司.png')" alt="" />
                                                        {{ item.departmentName }}
                                                    </section>
                                                </footer>
                                            </div>
                                        </section>
                                    </a-col>
                                </a-row>
                                <div style="text-align:center;margin:0 auto;padding-top:44px">
                                    <a-pagination show-quick-jumper :current="pageOptions.pageNo" :defaultPageSize="pageOptions.pageSize" :total="pageOptions.total" @showSizeChange="handlePageSizeChange" @change="handlePageChange" />
                                    <br />
                                </div>
                            </template>
                            <NoData v-else></NoData>
                        </a-spin>
                    </div>
                </main>
                <aside>
                    <!-- <div class="question-area">
                        <p class="title">疑问解答</p>
                        <div class="question-list">
                            <a class="question-item" :href="item.href" v-for="item in questionLinks" :key="item.key" target="__blank">
                                {{ item.title }}
                            </a>
                        </div>
                    </div>
                    <a-divider /> -->
                    <div class="followus-area">
                        <p class="title">关注我们</p>
                        <div class="qrcode">
                            <img :src="require('@/assets/index/tucen3.png')" alt="" />
                        </div>
                        <article>
                            想了解更多信息？
                            <br />
                            想更及时掌握？
                            <br />
                            请扫一扫二维码，关注“{{ city.name }}发布”公众号
                        </article>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import TabBar from './components/TabBar'
import NoData from '@/components/NoData'
import LoginModal from '@/components/LoginModal'
import { getApplicationList, getFieldList, getColllectedList, collectAppResult, cancelCollectAppResult } from './api'
import { getCookie } from '@/utils/cookie'
const defaultPageOptions = {
    pageNo: 1,
    pageSize: 8,
    total: 0
}
const defaultConditions = {
    fieldId: '',
    order: 'onlineTime'
}
export default {
    components: {
        TabBar,
        NoData,
        LoginModal
    },
    data() {
        return {
            openModal: false,
            list: [],
            collectedList: [],
            loading: true,
            pageOptions: {
                ...defaultPageOptions
            },
            conditions: { ...defaultConditions }, //搜索条件
            orderTabs: [
                { value: 'onlineTime', label: '发布时间', active: true },
                { value: 'useCount', label: '访问次数', active: false }
            ], //筛选内容数组
            fieldOptions: [], //领域选项（通过接口获取）
            questionLinks: [
                {
                    key: 0,
                    title: '申请数据集有什么用？',
                    href: 'https://www.baidu.com/s?ie=UTF-8&wd=申请数据集有什么用？'
                },
                {
                    key: 1,
                    title: '数据可以多次申请吗？',
                    href: 'https://www.baidu.com/s?ie=UTF-8&wd=数据可以多次申请吗？'
                },
                {
                    key: 2,
                    title: '数据集申请需要什么样流程？',
                    href: 'https://www.baidu.com/s?ie=UTF-8&wd=数据集申请需要什么样流程？'
                }
            ],
            KF_USER: getCookie('KF_USER') ? JSON.parse(getCookie('KF_USER')) : {}
        }
    },
    // mounted() {
    //     this.getFieldList()
    //     this.getList()
    // },
    activated() {
        this.getFieldList()
        this.getList()
    },
    methods: {
        moment,
        goDetail(id) {
            this.$router.push({
                name: 'ApplicationResultsDetail',
                params: {
                    id
                }
            })
        },
        changeOpenModal(val) {
            this.openModal = val
        },
        getList() {
            this.loading = true
            Promise.allSettled([this.getColllectedList(), this.getApplicationList()])
                .then(res => {
                    this.collectedList = res[0].status == 'fulfilled' ? res[0].value : []
                    if (res[1].status == 'fulfilled') {
                        this.list = res[1].value.records.map(item => {
                            let index = this.collectedList.findIndex(el => {
                                return el == item.id
                            })
                            item.hasCollected = index != -1 //hasCollected为true已收藏
                            return item
                        })
                        this.pageOptions.total = res[1].value.total
                    } else {
                        this.list = []
                        this.pageOptions.total = 0
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                })
        },
        getColllectedList() {
            return new Promise((resolve, reject) => {
                if (Object.prototype.hasOwnProperty.call(this.KF_USER, 'id')) {
                    getColllectedList({ id: this.KF_USER.id })
                        .then(res => {
                            resolve(res)
                            // this.collectedList = res
                        })
                        .catch(err => {
                            reject(err)
                            console.log(err)
                        })
                } else {
                    resolve([])
                }
            })
        },
        getApplicationList() {
            let params = {
                pageNo: this.pageOptions.pageNo,
                pageSize: this.pageOptions.pageSize,
                param: {
                    applicationResult: '1',
                    fieldId: this.conditions.fieldId
                }
            }
            return new Promise((resolve, reject) => {
                getApplicationList(this.conditions.order, params)
                    .then(res => {
                        console.log(res)
                        resolve(res)
                        // this.list = res.records
                        // this.pageOptions.total = res.total
                        // this.loading = false
                    })
                    .catch(err => {
                        reject(err)
                        console.log(err)
                    })
            })
        },
        toggleCollect(item) {
            if (!getCookie('KF_USER')) {
                this.openModal = true
                return
            }
            if (item.hasCollected) {
                this.cancelCollectAppResult(item)
                return
            }
            this.collectAppResult(item)
        },
        collectAppResult(item) {
            //收藏
            let params = {
                publishTime: moment(item.createDate).format('YYYY-MM-DD HH:mm:ss'),
                resourceAbstract: item.applicationDesc,
                resourceId: item.id,
                resourceName: item.applicationName,
                resourceProviderId: item.departmentId,
                resourceProviderName: item.departmentName,
                resourceUpdateTime: moment(item.updateDate).format('YYYY-MM-DD HH:mm:ss')
            }
            collectAppResult(params)
                .then(res => {
                    console.log(res)
                    // this.getList()
                    this.list.map(el => {
                        el.hasCollected = item.id === el.id ? true : el.hasCollected
                        return el
                    })
                })
                .catch(err => {
                    console.log(err)
                    this.$message.error('收藏失败')
                })
        },
        cancelCollectAppResult(item) {
            //取消收藏
            let params = {
                id: item.id,
                resourceType: 3
            }
            cancelCollectAppResult(params)
                .then(() => {
                    // this.getList()
                    this.list.map(el => {
                        el.hasCollected = item.id == el.id ? false : el.hasCollected
                        return el
                    })
                })
                .catch(err => {
                    console.log(err)
                    this.$message.error('取消收藏失败')
                })
        },
        onSelectFieldChange(e) {
            //切换选中应用成果领域
            let item = this._.find(this.fieldOptions, { id: e.target.value })
            this.fieldId = item ? item.id : ''
            this.getList()
        },
        handlePageChange(pageNo) {
            this.pageOptions.pageNo = pageNo
            this.getList()
        },
        handlePageSizeChange(current, size) {
            this.pageOptions.pageNo = 1 //切换页数
            this.pageOptions.pageSize = size
            this.getList()
        },
        switchOrderTab(value) {
            if (this._.find(this.orderTabs, { active: true }).value == value) return
            this.orderTabs = this.orderTabs.map(item => {
                item.active = item.value == value ? true : false
                return item
            })
            this.conditions.order = value
            this.getList()
        },
        getFieldList() {
            //获取数据领域列表

            getFieldList()
                .then(res => {
                    console.log(res)
                    if (Array.isArray(res)) {
                        this.fieldOptions = []
                        res.forEach(item => {
                            this.fieldOptions.push({
                                id: item.fieldId,
                                name: item.fieldName
                            })
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 32px 0;
    .wrap {
        .check-area {
            min-height: 110px;
            background-color: #f2f5f9;
            padding: 20px;
            .check-title {
                position: relative;
                font-size: 18px;
                line-height: 32px;
                color: #333333;
                display: inline-block;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 72px;
                    height: 2px;
                    background-color: #357df1;
                    border-radius: 1px;
                }
            }
            .check-list {
                padding: 20px 0 0;
                .check-item {
                    padding-right: 36px;
                    margin-bottom: 14px;
                    color: #333333;
                }
            }
        }
        .content {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            padding-top: 20px;
            .main {
                flex: 1;
                .main-header {
                    border-bottom: 1px solid #e7e7e7;
                    /deep/ .tab-list {
                        .tab-item {
                            padding: 0 12px 12px;
                        }
                    }
                }
                .main-content {
                    .main-result {
                        padding: 20px 0;
                        font-size: 20px;
                        color: #333333;
                        span {
                            color: #357df1;
                        }
                    }
                    .card-list {
                        .card-item {
                            background-color: #ffffff;
                            border: solid 1px #dbdbdb;
                            height: 300px;
                            .item-img {
                                position: relative;
                                height: 150px;
                                text-align: center;
                                cursor: pointer;
                                overflow: hidden;
                                img {
                                    max-height: 100%;
                                }
                            }
                            .item-info-box {
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                height: 150px;
                                .collect-icon {
                                    position: absolute;
                                    right: 8px;
                                    top: 6px;
                                    cursor: pointer;
                                }
                                .item-info-top {
                                    padding: 10px 14px;
                                    flex: 1;
                                    .item-title {
                                        width: 160px;
                                        cursor: pointer;
                                        color: #333333;
                                        font-size: 14px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    .item-field {
                                        color: #999999;
                                        font-size: 12px;
                                    }
                                    .icon-box {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 10px;
                                    }
                                }
                                footer {
                                    justify-self: flex-end;
                                    height: 42px;
                                    position: relative;
                                    border-top: 1px solid #dbdbdb;
                                    padding: 0 10px;
                                    .item-iconinfo {
                                        position: absolute;
                                        /* left: 50%; */
                                        top: 50%;
                                        transform: translateY(-50%);
                                        max-height: 40px;
                                        overflow: hidden;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                    }
                                }
                            }
                        }
                    }
                    .item-iconinfo {
                        padding: 0 14px 0 0;
                        color: #333333;
                        font-size: 12px;
                        img {
                            padding-right: 4px;
                        }
                    }
                }
            }
            aside {
                width: 267px;
                flex-shrink: 0;
                margin-left: 32px;
                .title {
                    color: #333333;
                    font-size: $fs14;
                    padding-bottom: 14px;
                    font-weight: bold;
                }
                .question-area {
                    .question-list {
                        .question-item {
                            display: block;
                            color: #333333;
                            font-size: $fs12;
                            margin-bottom: 12px;

                            &:hover {
                                text-decoration: underline;
                                text-decoration-color: #666;
                            }
                        }
                    }
                }
                .followus-area {
                    .qrcode {
                        position: relative;
                        width: 90px;
                        height: 90px;
                        background: #fff;
                        padding: 7px;
                        border: 1px solid #d5d6e3;
                        text-align: center;
                        img {
                            max-width: 100%;
                        }
                    }
                    article {
                        padding: 14px 0;
                        font-size: $fs12;
                        line-height: 18px;
                        color: #999999;
                    }
                }
            }
        }
    }
}
</style>
