<template>
    <div class="container contact-us">
        <div class="wrap wrap-flex">
            <main>
                <div class="wrap-title">联系我们</div>
                <div class="contact-us-block">
                    <h2>一、我有建议</h2>
                    <p>如果您对本网站提供的内容和服务有什么意见和建议，请您畅所欲言</p>
                </div>
                <div class="contact-us-block">
                    <h2>二、我要纠错</h2>
                    <p>如果您发现本网站提供的内容和服务方面存在链接、文字、图片、附件和格式等错误，请您纠错</p>
                </div>
                <div class="contact-us-block">
                    <h2>三、我有疑问</h2>
                    <p>如果您还有其他问题，请与我们联系，非常感谢您的理解和支持。</p>
                    <p>主办单位：舟山市大数据发展管理局</p>
                    <p>地 址：浙江省舟山市新城海天大道681号</p>
                    <p>邮政编码：316021</p>
                    <p>
                        电子邮箱：<a href="zsgov@zhoushan.gov.cn">zsgov@zhoushan.gov.cn</a>
                    </p>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    components: {},
    created() {},
    mounted() {},
    methods: {}
}
</script>

<style scoped lang="scss">
.container {
    width: 1200px;
    margin: 0 auto;
    padding: 36px 0 16px 0;
    .contact-us-block {
        h2 {
            font-size: 16px;
            font-weight: bold;
        }
        p {
            font-size: 16px;
            padding: 4px 0;
        }
    }
    .wrap {
        position: relative;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        padding: 20px;
        margin-bottom: 20px;
        .wrap-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            /* line-height: 9px; */
            letter-spacing: 0px;
            color: #303343;
            margin-bottom: 22px;
        }
        > aside {
            width: 290px;
            height: 207px;
            flex-shrink: 0;
            background-color: #fff;
            margin-right: 20px;
            overflow: hidden;
            img {
                max-width: 100%;
            }
        }
        > main {
            .info-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 18px;
                margin-bottom: 26px;
                .info-item {
                    position: relative;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    /* line-height: 9px; */
                    letter-spacing: 0px;
                    color: #8e92a1;
                    padding: 4px 16px;
                    &:not(:first-of-type) {
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 1px;
                            height: 18px;
                            background: #303343;
                        }
                    }
                    &:first-of-type {
                        padding-left: 0;
                    }
                    > span {
                        padding-left: 8px;
                        color: #303343;
                    }
                }
            }
        }
    }

    /deep/ button.ant-btn {
        width: auto;
        color: #1890ff;
    }
    .wrap-flex {
        display: flex;
        align-content: center;
    }
}
</style>
