<template>
    <div class="communication-list" v-if="list.length">
        <div class="communication-item" v-for="(item,index) in list" :key="index">
            <div class="communication-item-wrap">
                <div class="item-top">
                    <span class="item-title">{{item.resName}}</span>
                    <span class="item-time">{{formatDate(item.createDate)}}</span>
                </div>
                <div class="item-description">
                    <ellipsis-detail :id="index" :extraInfo="item" :content="'描述：' + (item.description || '--')" :rows="3"></ellipsis-detail>
                    <!-- <span>描述：{{item.description}}</span> -->
                </div>
                <div class="item-reply">
                    <div class="item-reply-user">
                        <div class="item-reply-user-icon">
                            <img :src="require('@/assets/communication/头像.png')" />
                        </div>
                        <span>管理员</span>
                    </div>
                    <div class="item-reply-content">
                        <div class="left-arrow"></div>
                        <p>{{item.replyContent}}<span>({{formatDate(item.replyDate)}})</span></p>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <no-data v-else info="暂无数据"></no-data>
</template>

<script>
import moment from 'moment'
import NoData from '@/components/NoData'
import EllipsisDetail from '@/components/EllipsisDetail'
export default {
    components:{
        NoData,
        EllipsisDetail
    },
    props:{
        list:{
            type:Array,
            default:()=>([])
        }
    },
    data(){
        return {}
    },
    mounted(){
        
    },
    methods:{
        moment,
        formatDate(date){
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        }
    }
}
</script>

<style lang="scss" scoped>
.communication-list{
    margin-bottom: 14px;
    .communication-item{
        border-bottom: 1px dotted #c7c7c7;
        padding: 10px;
        background: transparent;
        cursor: pointer;
        transition: all ease .2s;
        &:last-of-type{
            border-bottom: 1px dotted transparent;
        }
        .communication-item-wrap{
            .item-top{
                display: flex;
                // align-items: center;
                margin-bottom: 12px;
                .item-title{
                    font-weight: bold;
                    color: #333333;
                    font-size: 16px;
                    padding-right: 12px;
                    
                }
                .item-time{
                    margin-left: auto;
                    font-size: 16px;
                    color: #333333;
                    flex-shrink: 0;
                }
            }
            .item-description{
                color: #999999;
                font-size: 13px;
                margin-bottom: 14px;
                >span{
                    
                }
            }
            .item-reply{
                display: flex;
                .item-reply-user{
                    flex-shrink: 0;
                    display: flex;
                    // align-items: center;
                    margin-top: 10px;
                    margin-right: 20px;
                    .item-reply-user-icon{
                        width: 24px;
                        height: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 12px;
                        img{
                            max-width: 100%;
                        }
                    }
                    span{
                        font-size: 14px;
                        color: #0fa869;
                    }
                    
                }
                .item-reply-content{
                    position: relative;
                    flex: 1;
                    padding: 14px 18px;
                    background-color: #f2f5f9;
                    color: #333333;
                    font-size: 14px;
                    line-height: 24px;
                    .left-arrow{
                        position: absolute;
                        top: 13px;
                        right: 100%;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 7px 8px 7px 0;
                        border-color: transparent #f2f5f9 transparent transparent;
                    }
                    p{
                        span{
                            
                        }
                    }
                }
            }
        }
        &:hover{
            background: #f2f5f9;
            .communication-item-wrap{
                .item-reply{
                    .item-reply-content{
                        background-color: #fff;
                        .left-arrow{
                            border-color: transparent #fff transparent transparent;
                        }
                    }
                }
            }
        }
    }
}
</style>