import { baseRequest } from '@/API'

/**** 数据开放资源  接口列表 ****/
export function getInterfaceMarket(JSON) {
    return baseRequest({
        url: '/interface/openInterface/getInterfaceMarket',
        method: 'POST',
        data: JSON
    })
}

/**** 数据开放资源  数据列表 ****/
// 最新
export function getDataNew(JSON) {
    return baseRequest({
        url: '/catalog/dataOpen/newData',
        method: 'POST',
        data: JSON
    })
}

//最多下载
export function getDataMore(JSON) {
    return baseRequest({
        url: '/catalog/dataOpen/newData',
        method: 'POST',
        data: JSON
    })
}
