<template>
    <div class="center-content">
        <div class="wrapper">
            <div class="headImg"><p class="year">{{new Date().getFullYear()}}</p></div>
            
            <i class="line"></i>
            <div class="headBar">
                <div class="center">
                    <div class="item" v-for="(item, index) in topData" :key="index">
                        <span class="num"><countTo :startVal="0" :endVal="parseInt(item.num)" separator="" :duration="3000"></countTo></span>
                        <span class="lable">{{ item.name }}</span>
                    </div>
                </div>
            </div>
            <div class="chartCenter">
                <!--市级部门开放指数-->
                <div class="bk1">
                    <Myhead></Myhead>
                    <Mychart :chartData="bk1Data"></Mychart>
                </div>
                <div class="areaBlock">
                    <!--访问来源追溯-->
                    <div class="bk2">
                        <Myhead>
                            <template v-slot:title>
                                <span style="font-size: 20px;font-weight: normal;font-stretch: normal;line-height: 34px;letter-spacing: 0px;color: #2dfff8;z-index: 3;font-weight: bold;">访问来源追溯</span>
                            </template>
                        </Myhead>
                        <Mychart :chartData="bk2Data"></Mychart>
                    </div>
                    <!--访问次数-->
                    <div class="bk3">
                        <Myhead></Myhead>
                        <Mychart :chartData="bk3Data"></Mychart>
                    </div>
                </div>
                <!--领域开放排行-->
                <div class="bk4">
                    <Myhead></Myhead>
                    <Mychart :chartData="bk4Data"></Mychart>
                </div>
                <div class="areaBlock2">
                    <!--关注热度词云-->
                    <div class="bk5">
                        <Myhead></Myhead>
                        <Mychart :chartData="bk5Data"></Mychart>
                    </div>
                    <!--下载TOP10-->
                    <div class="bk6">
                        <Myhead></Myhead>
                        <Mychart :chartData="bk6Data"></Mychart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { resData } from './test'
import countTo from 'vue-count-to'
import { getOpenIndexData } from './api'
import { options1, options2, options3 } from './ChartsUtils/options/'
import Mychart from './components/chart'
import Myhead from './components/Headbox'
export default {
    name: 'OpenIndex',
    components: { Mychart, Myhead, countTo },
    data() {
        return {
            timer: null,
            topData: [
                { name: '累计访问次数', num: 0 },
                { name: '接口调用次数', num: 0 },
                { name: '平台注册用户数', num: 0 },
                { name: '开放数据集数量', num: 0 },
                { name: '开放接口量', num: 0 },
                { name: '开放部门数量', num: 0 }
            ],
            bk1Data: {
                id: 'chart1',
                class: 'tb1',
                ref: 'chart1',
                option: {}
            },
            bk2Data: {
                id: 'chart2',
                class: 'tb2',
                ref: 'chart2',
                option: {}
            },
            bk3Data: {
                id: 'chart3',
                class: 'tb3',
                ref: 'chart3',
                option: {}
            },
            bk4Data: {
                id: 'chart4',
                class: 'tb1',
                ref: 'chart4',
                option: {}
            },
            bk5Data: {
                id: 'chart5',
                class: 'tb5',
                ref: 'chart5',
                option: {}
            },
            bk6Data: {
                id: 'chart6',
                class: 'tb6',
                ref: 'chart6',
                option: {}
            }
        }
    },
    created() {},
    mounted() {
        this.getOpenIndexData()
        this.timer = setInterval(() => {
            this.getOpenIndexData()
        }, 1000 * 60 * 30)
    },
    methods: {
        getOpenIndexData() {
            // this.bk1Data.option = options1(resData.deptOpenIndexList)
            // this.bk2Data.option = options2(resData.visitSourceList)
            // this.bk3Data.option = options3(resData.visitSourceList)
            // this.bk4Data.option = this.getChart4(resData.dataDomainStatisticsList)
            // this.bk5Data.option = this.getChart5(resData.searchHotList)
            // this.bk6Data.option = this.getChart6(resData.downloadHotList)
            // this.topData[0].num = resData.totalVisitNum || 0
            // this.topData[1].num = resData.totalCallNum || 0
            // this.topData[2].num = resData.totalUserNum || 0
            // this.topData[3].num = resData.totalStructCatalogNum || 0
            // this.topData[4].num = resData.totalInterfaceNum || 0
            // this.topData[5].num = resData.totalOpenOrgNum || 0
            getOpenIndexData().then(res => {
                this.bk1Data.option = options1(res.deptOpenIndexList)
                this.bk2Data.option = options2(res.visitSourceList)
                this.bk3Data.option = options3(res.visitSourceList)
                this.bk4Data.option = this.getChart4(res.dataDomainStatisticsList)
                this.bk5Data.option = this.getChart5(res.searchHotList)
                this.bk6Data.option = this.getChart6(res.downloadHotList)
                this.topData[0].num = res.totalVisitNum || 0
                this.topData[1].num = res.totalCallNum || 0
                this.topData[2].num = res.totalUserNum || 0
                this.topData[3].num = res.totalStructCatalogNum || 0
                this.topData[4].num = res.totalInterfaceNum || 0
                this.topData[5].num = res.totalOpenOrgNum || 0
            })
        },
        getChart4(optionsData) {
            let chartData = []
            // let chartValue = []
            optionsData = optionsData.length > 21 ? optionsData.slice(0, 21) : optionsData
            let chartName = []
            let chartSelect = {}
            optionsData.map((item, index) => {
                chartData.push({ name: item.domainName, value: item.catalogNum, id: item.domainId, catalogNum: item.catalogNum, interfaceNum: item.interfaceNum })
                chartName.push(item.domainName)
                // chartValue.push(item.totalNum)
                chartSelect[item.domainName] = index < 6
            })
            console.log('chartData=>', chartData)

            let option = {
                title: {
                    text: '领域开放排行',
                    left: 'center',
                    top: 34,
                    textStyle: {
                        fontSize: 20,
                        fontStyle: 'normal',
                        color: 'rgba(45, 255, 248, 1)',
                        lineHeight: 34
                    }
                },
                legend: {
                    type: 'plain',
                    orient: 'vertical',
                    right: 50,
                    top: 100,
                    bottom: 60,
                    height: 260,
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 23,
                    textStyle: { color: '#8899cb7' },
                    data: chartName
                    // selected: chartSelect
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} <br/>占比：{c} ({d}%)'
                },
                series: [
                    {
                        name: '底盘',
                        type: 'pie',
                        radius: '70%',
                        center: ['30%', '55%'],
                        color: 'rgba(12,50,101,0.8)',
                        labelLine: { show: false },
                        data: [100],
                        silent: 'true' //取消点击和hover响应
                        // tooltip: {
                        //     show: false
                        // }
                    },
                    {
                        name: '领域',
                        type: 'pie',
                        radius: '65%',
                        center: ['30%', '55%'],
                        labelLine: {
                            length: 20
                        },
                        hoverOffset: 20,
                        selectedMode: 'true', //选中模式,默认关闭
                        selectedOffset: 10, //选中偏移
                        data: chartData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
            return option
        },
        getChart5(optionsData) {
            let gridSize
            if (optionsData.length > 20) {
                gridSize = 24
                optionsData.slice(0, 20)
            } else {
                gridSize = 50
            }
            let chartData = []
            optionsData.map(item => {
                chartData.push({ name: item.name, value: item.num })
            })
            let option = {
                aria: { show: true },
                title: {
                    text: '关注热度词云',
                    left: 'center',
                    top: 34,
                    textStyle: {
                        fontSize: 20,
                        fontStyle: 'normal',
                        color: 'rgba(45, 255, 248, 1)',
                        lineHeight: 34
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}<br/>热度：{c}'
                },
                // dataset: {
                //     source: {
                //         词云: chartData
                //     }
                // },
                series: [
                    {
                        name: '热度指数',
                        type: 'wordCloud',
                        top: 80,
                        left: 'center',
                        width: '90%',
                        sizeRange: [12, 60],
                        rotationRange: [0, 0],
                        // rotationStep: 20,
                        // textPadding: 60,
                        gridSize: gridSize,
                        // drawOutOfBound: false,
                        // Global text style
                        textStyle: {
                            fontFamily: 'sans-serif',
                            fontWeight: 'bold',
                            normal: {
                                color: function() {
                                    return 'rgb(' + [Math.round(Math.random() * 255), Math.round(Math.random() * 255), Math.round(Math.random() * 255)].join(',') + ')'
                                }
                                // color: function() {
                                //     // Random color
                                //     return ['#1df1ff', '#0e8afc', '#11c9e6', '#ffffff'][Math.floor(Math.random() * 4)]
                                // }
                            }
                        },
                        // maskImage: maskImage,
                        data: chartData //word
                    }
                ]
            }
            return option
        },
        getChart6(optionsData) {
            optionsData = optionsData.length > 10 ? optionsData.slice(0, 10) : optionsData
            // let chartData = []
            let chartValue = []
            let chartName = []
            optionsData.map(item => {
                // chartData.push({ name: item.catalogName, value: item.dlNum, id: item.id })
                chartName.push(item.catalogName)
                chartValue.push(item.dlNum)
            })
            let option = {
                title: {
                    text: '下载TOP10',
                    left: 'center',
                    top: 34,
                    textStyle: {
                        fontSize: 20,
                        fontStyle: 'normal',
                        color: 'rgba(45, 255, 248, 1)',
                        lineHeight: 34
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { type: 'shadow' },
                    // formatter: '{b}<br/>下载量：{c}'
                    formatter: function(params) {
                        let htmlStr = ''
                        htmlStr += params[0].name + '<br/>'
                        htmlStr += '下载排名：' + (params[0].dataIndex + 1) + '<br/>'
                        htmlStr += '下载量：' + params[0].value[1]
                        return htmlStr
                    }
                },
                dataset: {
                    source: {
                        榜名: chartName,
                        下载量: chartValue
                    }
                },
                xAxis: [
                    {
                        // type: 'value',
                        type: 'log',
                        show: false,
                        splitLine: { show: false } //分割线，默认数值轴显示，类目轴不显示
                    }
                ],
                yAxis: [
                    {
                        type: 'category',
                        inverse: true,
                        nameLocation: 'start',
                        axisLine: {
                            //轴线
                            show: false
                        },
                        axisTick: {
                            //轴刻度
                            show: false
                        },
                        axisLabel: {
                            //坐标轴刻度标签
                            //调整y轴的lable
                            interval: 0, //y轴信息全部显示;1：隔一个显示一个
                            margin: '0',
                            align: 'left',
                            verticalAlign: 'bottom',
                            textStyle: {
                                fontSize: 12,
                                color: '#889cb7',
                                lineHeight: 26
                            }
                        }
                    }
                ],
                grid: {
                    top: 100,
                    bottom: 33,
                    right: 40,
                    width: '80%'
                    // containLabel: true
                },
                series: [
                    {
                        name: '下载排名',
                        type: 'bar',
                        // barWidth: '10%',
                        barWidth: '8',
                        label: {
                            show: true,
                            position: 'right',
                            color: '#ffffff'
                        },
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 1,
                                y2: 0,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#0A5B94' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#2dfff8' // 100% 处的颜色
                                    }
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        showBackground: true, //柱条背景色
                        backgroundStyle: {
                            color: 'rgba(11, 47, 89, .8)'
                        }
                        // emphasis: {//高亮标签和图形样式
                        //     label: { show: 'true', position: 'right' }
                        // },
                    }
                ]
            }
            return option
        }
    },
    beforeDestroy() {
        this.timer = null
        clearInterval(this.timer)
    }
}
</script>

<style lang="scss" scoped>
@import './source/style';
.bk1,
.bk2,
.bk3,
.bk4,
.bk5,
.bk6 {
    position: relative;
}
</style>
