<template>
    <div class="center-content">
        <main class="main">
            <div class="header">
                <p class="title">接口申请表修改</p>
            </div>
            <div class="main-info">
                <a-form :form="form">
                    <section class="main-block">
                        <div class="main-title">
                            <SameStyleBox title="接口栏"></SameStyleBox>
                        </div>
                        <!-- 接口信息 -->
                        <div class="interface-box">
                            <section class="interface-card">
                                <div class="interface-header">
                                    <aside>
                                        <img :src="dataInterface.interfaceImg" alt="" />
                                    </aside>
                                    <p class="interface-title">{{ dataInterface.name }}</p>
                                    <div class="interface-tags">
                                        <div class="mgr16 tag tag-normal" v-if="!!dataInterface.officeName">
                                            {{ dataInterface.officeName }}
                                        </div>
                                        <div class="mgr16 tag tag-normal" v-if="public_property_options.length">
                                            {{ showStateLabel(isOpen, public_property_options) }}
                                        </div>
                                        <!-- <div class="mgr16 tag tag-success" v-if="interface_state_options.length">
                                            {{ showStateLabel(dataInterface.interfaceState, interface_state_options) }}
                                        </div> -->
                                    </div>
                                </div>
                                <div class="interface-selections">
                                    <section class="select-item">
                                        <Select name="接口协议" :value="showStateLabel(dataInterface.interfaceProtocol, interface_protocol_options)" :options="interface_protocol_options" :disabled="true"></Select>
                                    </section>
                                    <section class="select-item">
                                        <Select name="调用方式" :value="showStateLabel(dataInterface.callMode, call_mode_options)" :disabled="true" :options="call_mode_options"></Select>
                                    </section>
                                    <section class="select-item">
                                        <Select name="数据格式" :value="showStateLabel(dataInterface.inparamFormat, datatype_options)" :options="datatype_options" :disabled="true"></Select>
                                    </section>
                                </div>
                            </section>
                        </div>
                    </section>
                    <section class="main-block">
                        <div class="main-title">
                            <SameStyleBox title="使用信息"></SameStyleBox>
                        </div>
                        <div class="apply-info-table">
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    申请标题
                                </p>
                                <div class="apply-info-data">
                                    <a-form-item>
                                        <a-input
                                            type="text"
                                            class="apply-info-input"
                                            v-decorator="[
                                                'title',
                                                {
                                                    initialValue: serviceInfo.applyformName,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '申请标题不能为空'
                                                        }
                                                    ],
                                                    trigger: 'change'
                                                }
                                            ]"
                                        ></a-input>
                                    </a-form-item>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    关联应用
                                </p>
                                <div class="apply-info-data">
                                    <!-- <section
                                        style="color:#666;cursor:pointer"
                                        @click="
                                            () => {
                                                showAppChooseModal = true
                                            }
                                        "
                                    >
                                        {{ relateAppInfo && Object.keys(relateAppInfo).length ? relateAppInfo.appName : '请选择' }}
                                    </section> -->
                                    <a-form-item>
                                        <a-select
                                            placeholder="搜索关联应用"
                                            @change="handleRelateAppChange"
                                            v-decorator="[
                                                'applicationName',
                                                {
                                                    initialValue: serviceInfo.applicationName,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '未选择关联应用'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        >
                                            <a-select-option :value="item.id" v-for="item in relateAppList" :key="item.id">
                                                {{ item.applicationName }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>

                                    <!-- <input type="text" placeholder="请选择" class="apply-info-input" /> -->
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    申请人姓名
                                </p>
                                <div class="apply-info-data">
                                    <a-form-item>
                                        <a-input
                                            disabled
                                            type="text"
                                            class="apply-info-input"
                                            v-decorator="[
                                                'userName',
                                                {
                                                    initialValue: serviceInfo.applicantName,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '申请人姓名不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        ></a-input>
                                    </a-form-item>
                                </div>
                            </section>
                            
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    申请人电话
                                </p>
                                <div class="apply-info-data">
                                    <a-form-item>
                                        <a-input
                                            disabled
                                            class="apply-info-input"
                                            v-decorator="[
                                                'mobile',
                                                {
                                                    initialValue: serviceInfo.applicantPhone,
                                                    rules: [
                                                        {
                                                            pattern: /^1[0-9]{10}$/,
                                                            message: '请输入正确的手机号'
                                                        },
                                                        {
                                                            required: true,
                                                            message: '申请人电话不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        ></a-input>
                                    </a-form-item>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    使用目的
                                </p>
                                <div class="apply-info-data">
                                    <input type="text" class="apply-info-input" placeholder="非必填" v-model="serviceInfo.purpose" />
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    申请人邮箱
                                </p>
                                <div class="apply-info-data">
                                    <a-form-item>
                                        <a-input
                                            type="email"
                                            class="apply-info-input"
                                            v-decorator="[
                                                'email',
                                                {
                                                    initialValue: serviceInfo.applicantEmail,
                                                    rules: [
                                                        {
                                                            type: 'email',
                                                            message: '邮箱格式不正确'
                                                        },
                                                        {
                                                            required: true,
                                                            message: '申请人邮箱不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        ></a-input>
                                    </a-form-item>
                                </div>
                            </section>
                            <!-- <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    申请人部门
                                </p>
                                <div class="apply-info-data">
                                    <a-form-item>
                                        <a-input
                                            disabled
                                            class="apply-info-input"
                                            v-decorator="[
                                                'orgName',
                                                {
                                                    initialValue: userInfo.orgName,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '申请人部门不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        ></a-input>
                                    </a-form-item>
                                </div>
                            </section> -->
                        </div>
                    </section>
                    <section class="main-block">
                        <div class="main-title">
                            <SameStyleBox title="服务使用信息"></SameStyleBox>
                        </div>
                        <div class="use-limit">
                            <section class="limit-item">
                                <p class="">
                                    <span class="require">*</span>
                                    日调用上限
                                </p>
                                <div class="limit-nums">
                                    <a-form-item>
                                        <a-input-number
                                            :step="10"
                                            v-decorator="[
                                                'dayLimit',
                                                {
                                                    initialValue: serviceInfo.callnumberPerday,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '日调用上限不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        />
                                    </a-form-item>
                                    <span>次</span>
                                </div>
                            </section>
                            <section class="limit-item">
                                <p class="">
                                    <span class="require">*</span>
                                    总调用次数
                                </p>
                                <div class="limit-nums">
                                    <a-form-item>
                                        <a-input-number
                                            :step="10"
                                            v-decorator="[
                                                'allLimit',
                                                {
                                                    initialValue: serviceInfo.callnumberTotal,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '总调用次数不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        />
                                    </a-form-item>

                                    <span>次</span>
                                </div>
                            </section>
                            <section class="limit-item">
                                <p class="">
                                    <span class="require">*</span>
                                    单次调用最大条数
                                </p>
                                <div class="limit-nums">
                                    <a-form-item>
                                        <a-input-number
                                            :step="10"
                                            v-decorator="[
                                                'singleNums',
                                                {
                                                    initialValue: serviceInfo.callnumberPertime,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '单次调用最大条数不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        />
                                    </a-form-item>
                                    <span>次</span>
                                </div>
                            </section>
                        </div>
                    </section>
                    <section class="main-block" v-if="whiteList.length">
                        <div class="main-title">
                            <SameStyleBox title="白名单管理"></SameStyleBox>
                        </div>
                        <div class="whitelist">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>IP地址</th>
                                        <th>IP备注</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in whiteList" :key="item.name">
                                        <td>{{ item.ipaddress }}</td>
                                        <td>{{ item.name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </a-form>
            </div>
            <div class="footer">
                <a-button type="primary" class="btn-submit" @click="handleSubmit" :loading="hasSubmit">{{ hasSubmit ? '正在提交' : '提交申请' }}</a-button>
                <a-button class="btn-cancel" @click="handleCancel">取消</a-button>
            </div>
        </main>
        <!-- <ApplicationChooseModal :show="showAppChooseModal" @confirm="confirmChooseApp" @cancel="cancelChooseApp"></ApplicationChooseModal> -->
    </div>
</template>
<script>
// import ApplicationChooseModal from '../components/ApplicationChooseModal'
import Select from '@/views/InterfaceService/components/Select.vue'
import {  getAllSelectOptions, applyDataInterface, getRelateAppList, getApplicationDetail } from './api'
import { showStateLabel } from '@/utils/tools.js'
import { getCookie } from '@/utils/cookie.js'
export default {
    components: {
        Select
        // ApplicationChooseModal
    },
    name: 'DataInterfaceEdit',
    data() {
        return {
            id: '',
            isOpen: '1',
            hasSubmit: false, //防止重复点击
            hasApply: false, //用于判断申请成功后，是否重复点击申请
            userInfo: {},
            relateAppInfo: {},
            dataInterface: {},
            serviceInfo: {},
            applicationInfoEntity: {},
            whiteList: [],
            purpose: '',
            relateAppList: [],
            public_property_options: [
                {
                    label: '公共开放',
                    value: '1'
                },
                {
                    label: '受限',
                    value: '2'
                }
            ], //是否受限
            interface_state_options: [], //上下线，接口状态
            interface_protocol_options: [], //协议(http等)
            call_mode_options: [], //接口调用方式(get,post)
            datatype_options: [
                {
                    value: '0',
                    label: 'JSON'
                },
                {
                    value: '1',
                    label: 'XML'
                }
            ],
            limitCount: {
                dayLimit: 1000,
                allLimit: 500000,
                singleNums: 1000
            }
        }
    },
    created() {
        this.id = this.$route.params.id
        this.form = this.$form.createForm(this, { name: 'form_dia' })
    },
    async mounted() {
        this.getUserInfo()
        await this.getRelateAppList()
        this.getAllSelectOptions()
        this.getApplicationDetail()
    },
    methods: {
        showStateLabel,
        getApplicationDetail() {
            getApplicationDetail({ id: this.$route.params.id }) 
                .then(res => {
                    this.dataInterface = res.dataInterface
                    this.applicationInfoEntity = res.applicationInfoEntity
                    this.serviceInfo = res.serviceInfo
                    // console.log('relateAppList=>', this.relateAppList)
                    this.relateAppInfo = this._.find(this.relateAppList, { id: this.serviceInfo.applicationId })
                    try {
                        this.whiteList = JSON.parse(this.serviceInfo.ipAddress) || []
                    } catch (error) {
                        this.whiteList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getUserInfo() {
            let data = getCookie('KF_USER')
            if (!data) {
                this.$message.warn('用户信息获取失败')
                this.userInfo = {}
                return
            }
            this.userInfo = JSON.parse(data) || {}
        },
        getRelateAppList() {
            let params = {
                param: {
                    applicationState: 3
                    // applicationName: '', //应用名称
                    // createBy: '', //创建人id
                    // endCreateTime: '', //结束时间
                    // startCreateTime: '' //开始时间
                }
            }
            getRelateAppList(params)
                .then(res => {
                    // console.log('getRelateAppList=>', res)
                    this.relateAppList = res.records || []
                })
                .catch(err => {
                    console.log(err)
                })
        },
        showGoAddAppConfirm() {
            let _this = this
            this.$info({
                title: '您还未注册应用，无法使用接口申请！',
                closable: true,
                okText: '前往应用注册',
                maskClosable: true,
                cancelText: '取消',
                onOk() {
                    _this.$router.push({
                        name: 'MyAppList'
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        handleSubmit(e) {
            if (this.hasSubmit) return //重复点击处理
            if (this.hasApply) {
                this.$message.warn('请勿重复申请！')
                return
            }
            e.preventDefault()
            let _this = this
            this.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    // console.log('Received values of form: ', values)
                    if (!_this.relateAppList.length) {
                        //已登录，未申请接口，但是该账号未关联应用，弹出提示去关联应用提示框随后跳到新增关联应用页面
                        _this.showGoAddAppConfirm()
                        return
                    }
                    if (!Object.keys(_this.relateAppInfo).length) {
                        //未选择关联应用
                        _this.$message.error('请选择关联应用')
                        return
                    }
                    let params = {
                        id: _this.$route.params.id,
                        applicantEmail: values.email,
                        applicantId: _this.userInfo.id,
                        applicantName: _this.userInfo.userName,
                        applicantPhone: _this.userInfo.mobile,
                        applicationId: _this.relateAppInfo.id,
                        appKey: _this.relateAppInfo.appkey,
                        applicationName: _this.relateAppInfo.applicationName,
                        applyDepartment: _this.userInfo.orgId,
                        applyDepartmentName: _this.userInfo.orgName,
                        applyformName: values.title,
                        callnumberPerday: values.dayLimit,
                        callnumberPertime: values.singleNums,
                        callnumberTotal: values.allLimit,
                        interfaceId: _this.dataInterface.id,
                        interfaceName: _this.dataInterface.name,
                        ipAddress: JSON.stringify(_this.whiteList),
                        purpose: _this.serviceInfo.purpose
                    }
                    this.hasSubmit = true
                    _this.applyDataInterface(params)
                    // console.log('params=>', params)
                }
            })
        },
        handleCancel() {
            //点击下方取消
            this.$router.go(-1)
        },
        getAllSelectOptions() {
            // business_code0:业务类型,interface_protocol:协议(http等),call_mode:接口调用方式(get,post),coding:编码格式(utf-8等)
            let params = {
                types: ' interface_state, interface_protocol, call_mode'
            }
            getAllSelectOptions(params)
                .then(res => {
                    // console.log('getAllSelectOptions=>', res)
                    // this.public_property_options = res.public_property
                    this.interface_protocol_options = res.interface_protocol
                    this.call_mode_options = res.call_mode
                    this.interface_state_options = res.interface_state
                })
                .catch(err => {
                    // console.log(err)
                })
        },
        applyDataInterface(params) {
            applyDataInterface(params)
                .then(res => {
                    //申请成功处理
                    // console.log(res)
                    this.$message.success('申请成功！')
                    this.hasSubmit = false
                    this.hasApply = true
                    setTimeout(() => {
                        this.$router.push({
                            name: 'InterfaceServiceList'
                        })
                    }, 500)
                })
                .catch(err => {
                    console.log(err)
                    this.hasSubmit = false
                })
        },
        handleRelateAppChange(value) {
            this.relateAppInfo = this._.find(this.relateAppList, { id: value })
            console.log(this.relateAppInfo)
            try {
                this.whiteList = JSON.parse(this.relateAppInfo.ipAddress) || []
            } catch (error) {
                this.whiteList = []
            }
        }
    }
}
</script>
<style scoped lang="scss">
.center-content {
    padding-top: 24px;
    padding-bottom: 48px;
    .main {
        padding: 40px;
        background: #fff;
        .header {
            display: flex;
            flex-flow: row nowrap;
            padding-bottom: 27px;
            border-bottom: 2px solid #ebeced;
            .title {
                color: #000025;
                font-size: 30px;
                font-weight: bold;
                padding-right: 14px;
            }
        }
        .main-info {
            .main-block {
                margin-bottom: 18px;
                .interface-box {
                    .interface-card {
                        background-color: #ffffff;
                        border: solid 1px #cccccc;
                        padding: 20px 30px;
                        .interface-header {
                            display: flex;
                            align-items: center;
                            aside {
                                width: 40px;
                                height: 40px;
                                border: solid 1px rgba(193, 200, 210, 0.5);
                                overflow: hidden;
                                img {
                                    max-width: 100%;
                                    height: auto;
                                    vertical-align: top;
                                }
                            }
                            .interface-title {
                                font-size: 16px;
                                letter-spacing: 1px;
                                color: #0184ff;
                                margin-left: 16px;
                                font-weight: bold;
                            }
                            .interface-tags {
                                display: flex;
                                align-items: center;
                                margin-left: 34px;
                                flex-shrink: 0;
                                .tag {
                                    padding: 6px 14px;
                                    border-radius: 4px;
                                    font-size: 12px;
                                }
                                .tag-normal {
                                    background-color: rgba(24, 144, 255, 0.1);
                                    color: #1890ff;
                                }
                                .tag-success {
                                    background-color: rgba(19, 194, 194, 0.1);
                                    color: #13c2c2;
                                }
                                .tag-warning {
                                    background-color: rgba(255, 1, 1, 0.1);
                                    color: #ff0101;
                                }
                                .mgr16 {
                                    margin-right: 16px;
                                }
                            }
                        }
                        .interface-selections {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 30px;
                        }
                    }
                }
                .apply-info-table {
                    display: flex;
                    flex-flow: row wrap;
                    border-top: 1px solid #d5d6e3;
                    border-bottom: none;
                    .apply-info-item {
                        display: flex;
                        width: 50%;
                        flex-shrink: 0;
                        align-content: center;
                        /* border: 1px solid #d5d6e3; */
                        height: 50px;
                        line-height: 50px;
                        padding: 0 20px;
                        border-bottom: 1px solid #d5d6e3;
                        &:first-of-type {
                            /* width: 100%; */
                            .apply-info-data {
                                /* margin-left: 46px; */
                                /* width: 100%; */
                            }
                        }
                        &:nth-of-type(odd) {
                            /* border-right: 1px solid #d5d6e3; */
                            border-left: 1px solid #d5d6e3;
                        }
                        &:nth-of-type(even) {
                            border-left: 1px solid #d5d6e3;
                            border-right: 1px solid #d5d6e3;
                        }
                        > p {
                            position: relative;
                            flex-shrink: 0;
                            color: #000025;
                            font-size: $fs12;
                            .require {
                                position: absolute;
                                left: -8px;
                                top: 0;
                                color: #ff0000;
                            }
                        }
                        .apply-info-data {
                            margin-left: auto;
                            width: 420px;
                            .apply-info-input {
                                width: 100%;
                                background: transparent;
                                border: none;
                                outline: none;
                                box-shadow: none;
                                color: rgba(0, 0, 0, 0.65);
                            }
                            /deep/ .ant-select-selection__rendered {
                                margin-left: 0px;
                            }
                        }
                    }
                }
                .use-limit {
                    display: flex;
                    justify-content: space-between;
                    .limit-item {
                        display: flex;
                        align-items: center;
                        margin-left: 12px;
                        > p {
                            margin-right: 10px;
                            font-size: $fs12;
                            color: #000025;
                        }
                        .limit-nums {
                            width: 140px;
                            height: 30px;
                            background-color: #ffffff;
                            border: solid 1px #cccccc;
                            padding: 0 10px;
                            display: flex;
                            align-items: center;
                            /deep/ .ant-input-number {
                                border: none;
                                background: transparent;
                                outline: none;
                                box-shadow: none;
                                height: auto;
                            }
                            /deep/ .ant-form-explain {
                                left: -12px;
                                bottom: -10px;
                                white-space: nowrap;
                            }
                            > span {
                                margin-left: auto;
                                font-size: $fs12;
                                color: #000025;
                            }
                        }
                    }
                }
                .whitelist {
                    table {
                        width: 100%;
                        border-left: solid 1px #d5d6e3;
                        border-right: solid 1px #d5d6e3;
                        tr {
                            border-bottom: 1px solid #d5d6e3;
                            font-size: $fs12;
                            &:first-of-type {
                                border-top: 1px solid #d5d6e3;
                            }
                            th {
                                width: 50%;
                                height: 30px;
                                padding: 0 30px;
                                line-height: 30px;
                                background-color: #eef1f8;
                            }
                            td {
                                padding: 0 30px;
                                height: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
        /deep/ .ant-form-item {
            margin-bottom: 0;
            height: 50px;
            line-height: 50px;
        }
        /deep/ .ant-form-item-control {
            line-height: inherit;
        }
        /deep/ .ant-form-explain {
            position: absolute;
            bottom: 4px;
            font-size: 12px;
        }
        /deep/ .ant-form-item-children {
            vertical-align: super;
        }
        /deep/ .apply-info-input {
            padding-left: 0;
        }
        /deep/ .ant-select-selection {
            border: 1px solid transparent;
            box-shadow: none;
            background-color: transparent;
            outline: none;
        }
        .footer {
            display: flex;
            justify-content: center;
            margin-top: 80px;
            button {
                margin: 0 14px;
                height: 40px;
                line-height: 40px;
                border-radius: 4px;
            }
            .btn-cancel {
                background-color: #f4f4f4;
                border: solid 1px #dddddd;
                color: #999999;
            }
        }
    }
}
</style>
