<template>
  <div class="tab-list">
        <section class="tab-item" v-for="(item, index) in tabList" :key="item.enName" :class="{ active: activeTab === index }" @click.stop="onClickTab(index)">
            <div class="tab-icon">
                <img :src="activeTab === index ? item.activeIcon : item.icon" />
            </div>
            <div class="tab-title">
                <p>{{ item.name }}</p>
                <span>{{ item.enName }}</span>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props:{
        activeTab:{
            type:Number,
            default:1
        },
        tabList:{
            type:Array,
            default:()=>([])
        }
    },
    data(){
        return {
            
        }
    },
    mounted(){
        
    },
    methods:{
        onClickTab(index) {
            if (this.activeTab == index) return
            this.$emit('clickItem',index)
        }
    }
}
</script>

<style lang="scss" scoped>
.tab-list {
    .tab-item {
        width: 175px;
        height: 60px;
        display: flex;
        align-items: center;
        color: #333333;
        margin-bottom: 20px;
        background: #fff;
        cursor: pointer;
        .tab-icon {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
            }
            margin-left: 15px;
        }
        .tab-title {
            margin-left: 15px;
            height: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
                font-size: 16px;
                line-height: 16px;
            }
            span {
                font-size: 10px;
                line-height: 10px;
            }
        }
        &.active {
            background-color: #2b85fb;
            color: #fff;
        }
    }
}
</style>