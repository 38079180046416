<template>
  <div class="ellipsis-container">
    <div class="textarea-container" :ref="'shadow' + id">
      <textarea :rows="rows" readonly tabindex="-1"></textarea>
    </div>
    {{ showContent }}
    <slot name="ellipsis" v-if="(textLength < content.length) && !expanded">
        {{ ellipsisText }}
        <span class="ellipsis-btn" @click="onClickExpand">展开<img :src="require('@/assets/communication/展开.png')" style="margin-left:4px;width:8px;"></span>
    </slot>
    <slot name="collapse" v-if="(textLength < content.length) && expanded">
        <span class="ellipsis-btn" @click="onClickCollapse">收起<img :src="require('@/assets/communication/收起.png')" style="margin-left:4px;width:8px;"></span>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    id:{
      type:[Number,String],
      default:''
    },
    content: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: '查看全部'
    },
    ellipsisText: {
      type: String,
      default: '...'
    },
    rows: {
      type: Number,
      default: 6
    },
    btnShow: {
      type: Boolean,
      default: false
    },
    extraInfo:{
        type: Object,
        default: ()=>({})
    },
  },
  data () {
    return {
      textLength: 0,
      beforeRefresh: null,
      expanded:false
    }
  },
  computed: {
    showContent () {
      if(this.expanded){
        return this.content
      }
      const length = this.beforeRefresh ? this.content.length : this.textLength
      return this.content.substr(0, this.textLength)
    },
    watchData () { // 用一个计算属性来统一观察需要关注的属性变化
      return [this.content, this.btnText, this.ellipsisText, this.rows, this.btnShow]
    }
  },
  watch: {
    watchData: {
      immediate: true,
      handler () {
        // this.refresh()
      }
    },
  },
  mounted () {
    // 监听尺寸变化
    this.refresh()
  },
  beforeDestroy () {

  },
  methods: {
    refresh () { // 计算截取长度，存储于textLength中
      this.beforeRefresh && this.beforeRefresh()
      let stopLoop = false
      this.beforeRefresh = () => stopLoop = true
      this.textLength = this.content.length
      const checkLoop = (start, end) => {
        if (stopLoop || start + 1 >= end) return
        const rect = this.$el.getBoundingClientRect()
        const shadowRect = this.$refs['shadow'+this.id].getBoundingClientRect()
        const overflow = rect.bottom > shadowRect.bottom + 6
        overflow ? (end = this.textLength) : (start = this.textLength)
        this.textLength = Math.floor((start + end) / 2)
        this.$nextTick(() => checkLoop(start, end))
      }
      this.$nextTick(() => checkLoop(0, this.textLength))
    },
    // 展开按钮点击事件向外部emit
    // clickBtn (event) {
    //   this.$emit('click-btn', this.extraInfo)
    // },
    onClickExpand(){
      this.expanded = true
    },
    onClickCollapse(){
      this.expanded = false
    },
  }
} </script>
<style lang="scss" scoped>
.ellipsis-container{
    text-align:left;
    position:relative;
    line-height: 22px;
    padding:0 !important;
    color: #999999;
    .textarea-container{
        position:absolute;
        left: 0;
        right: 0;
        pointer-events: none;
        opacity: 0;
        z-index: -1;
        textarea{
            vertical-align: middle;
            padding: 0;
            resize: none;
            overflow: hidden;
            font-size: inherit;
            line-height: inherit;
            outline: none;
            border: none;
        }
    }
    .ellipsis-btn{
        color: #357df1;
        cursor: pointer;
    }
}
</style>