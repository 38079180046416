import { baseRequest } from '@/API'

//政策法规
export function getDataShpolicy(JSON) {
    return baseRequest({
        url: '/feedback/opennews/publishpolicy',
        method: 'POST',
        data: JSON
    })
}

//新闻列表
export function getDataNews(JSON) {
    return baseRequest({
        url: '/feedback/opennews/publishnews',
        method: 'POST',
        data: JSON
    })
}

//详情
export function GetDetail(JSON) {
    return baseRequest({
        url: '/feedback/opennews/detail',
        method: 'POST',
        data: JSON
    })
}
