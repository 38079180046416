<template>
    <a-modal class="demand-apply-dialog" :width="1000" centered :visible="true" :keyboard="false" :maskClosable="false" :closable="false">
        <template slot="title">
            <div class="title">
                <p>新增需求申请</p>
                <div class="icon-close" @click="onCancel"><a-icon type="close-circle" theme="filled" /></div>
            </div>
        </template>
        <div class="form">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :hideRequiredMark="false">
                <div class="form-table">
                    <a-row class="form-table-row">
                        <a-col>
                            <p class="form-item-label">
                                <span class="require">*</span>
                                资源名称
                            </p>
                            <a-form-model-item prop="resName">
                                <a-input v-model.trim="form.resName" placeholder="请输入资源名称" :maxLength="128"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row class="form-table-row">
                        <a-col :span="12">
                            <p class="form-item-label">所属行业</p>
                            <a-form-model-item prop="trades">
                                <a-select v-model="form.trades" placeholder="请选择所属行业" style="width: 100%" allow-clear show-search>
                                    <a-select-option :value="item" v-for="item in tradesList" :key="item">{{item}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <p class="form-item-label">
                                <span class="require">*</span>
                                数源部门
                            </p>
                            <a-form-model-item prop="deptName">
                                <a-cascader
                                    v-model="form.deptName"
                                    :field-names="{ label: 'idPocName', value: 'idPocName', children: 'child' }"
                                    :options="deptList"
                                    placeholder="请选择数源部门"
                                />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row class="form-table-row">
                        <a-col>
                            <p class="form-item-label">
                                <span class="require">*</span>
                                需求描述
                            </p>
                            <a-form-model-item prop="description">
                                <a-textarea v-model.trim="form.description" placeholder="请输入需求描述" :maxLength="2000" :rows="4"></a-textarea>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row class="form-table-row">
                        <a-col>
                            <p class="form-item-label">
                                <span class="require">*</span>
                                具体数据项
                            </p>
                            <a-form-model-item prop="dataItem">
                                <a-input v-model.trim="form.dataItem" placeholder="文件下载和其他类型时填写" :maxLength="1000"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row class="form-table-row">
                        <a-col>
                            <p class="form-item-label">
                                <span class="require">*</span>
                                应用场景
                            </p>
                            <a-form-model-item prop="applyScene">
                                <a-textarea v-model.trim="form.applyScene" placeholder="请具体说明数据用途" :maxLength="256" :rows="4"></a-textarea>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row class="form-table-row">
                        <a-col :span="12">
                            <p class="form-item-label">
                                <span class="require">*</span>
                                姓名/机构名称
                            </p>
                            <a-form-model-item prop="demander">
                                <a-input v-model.trim="form.demander" placeholder="请输入姓名/机构名称" :maxLength="256"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <p class="form-item-label">
                                <span class="require">*</span>
                                电话号码
                            </p>
                            <a-form-model-item prop="contact">
                                <a-input v-model.trim="form.contact" placeholder="请输入电话号码" :maxLength="16"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row class="form-table-row">
                        <a-col :span="12">
                            <p class="form-item-label">
                                <span class="require">*</span>
                                邮箱
                            </p>
                            <a-form-model-item prop="email">
                                <a-input v-model.trim="form.email" placeholder="请输入邮箱" :maxLength="64"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <p class="form-item-label">
                                <span class="require">*</span>
                                验证码
                            </p>
                            <a-form-model-item prop="verifyCode">
                                <a-input v-model.trim="form.verifyCode" placeholder="请输入验证码" :maxLength="5" allow-clear @change="onChangeVerfyCode"></a-input>
                            </a-form-model-item>
                            <div @click.stop="refreshVerifyCode" class="verify-code-wrap"><verify-code :code="verifyCode"></verify-code></div>
                        </a-col>
                    </a-row>
                </div>
            </a-form-model>
        </div>
        <template slot="footer">
            <a-button class="btn-submit" key="submit" type="primary" :loading="confirmLoading" @click="onSubmit">提交</a-button>
        </template>
    </a-modal>
</template>

<script>
import VerifyCode from '@/components/VerifyCode.vue'
import { addDataDemand, getTradesList, getDeptList } from '../api/index'
export default {
    components: { VerifyCode },
    props: {},
    data() {
        let validateVerifyCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('验证码不能为空'))
                return
            }
            if (this.verifyCode.toLowerCase() !== this.form.verifyCode.toLowerCase()) {
                callback(new Error('验证码错误，请重新输入'))
                return
            }
            callback()
        }
        return {
            confirmLoading: false,
            form: {
                applyScene: '',
                contact: '',
                dataItem: '',
                demander: '',
                deptName: undefined,
                description: '',
                email: '',
                resName: '',
                trades: undefined,
                verifyCode: ''
            },
            rules: {
                resName: [
                    {
                        required: true,
                        message: '资源名称不能为空',
                        trigger: ['change']
                    }
                ],
                deptName: [
                    {
                        required: true,
                        message: '数源部门不能为空',
                        trigger: ['change']
                    }
                ],
                description: [
                    {
                        required: true,
                        message: '需求描述不能为空',
                        trigger: ['change']
                    }
                ],
                dataItem: [
                    {
                        required: true,
                        message: '具体数据项不能为空',
                        trigger: ['change']
                    }
                ],
                applyScene: [
                    {
                        required: true,
                        message: '应用场景不能为空',
                        trigger: ['change']
                    }
                ],
                demander: [
                    {
                        required: true,
                        message: '姓名/机构名称不能为空',
                        trigger: ['change']
                    }
                ],
                contact: [
                    {
                        required: true,
                        message: '电话号码不能为空',
                        trigger: ['change']
                    }
                ],
                email: [
                    {
                        required: true,
                        message: '邮箱不能为空',
                        trigger: ['change']
                    }
                ],
                verifyCode: [{ validator: validateVerifyCode, trigger: ['blur'] }]
            },
            verifyCode: '',
            tradesList: [],
            deptList: []
        }
    },
    mounted() {
        this.getTradesList()
        this.getDeptList()
        this.generateCode()
    },
    methods: {
        // 点击“提交”
        onSubmit() {
            this.$refs['ruleForm'].validate(valid => {
                if (valid) {
                    this.addDataDemand()
                }else{
                    this.refreshVerifyCode()
                }
            })
        },
        addDataDemand() {
            let params = {
                applyScene: this.form.applyScene,
                contact: this.form.contact,
                dataItem: this.form.dataItem,
                demander: this.form.demander,
                deptName: this.form.deptName.join(','),
                description: this.form.description,
                email: this.form.email,
                resName: this.form.resName,
                trades: this.form.trades || '',
            }
            this.confirmLoading = true
            addDataDemand(params).then(res=>{
                this.$message.success('提交成功')
                this.$emit('submit')
            }).finally(()=>{
                this.confirmLoading = false
            })
        },
        // 点击“取消”
        onCancel() {
            this.$emit('cancel')
        },
        // 验证码处理
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        // 生成验证码
        generateCode(l = 5) {
            this.verifyCode = ''
            let str = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
            for (let i = 0; i < l; i++) {
                this.verifyCode += str[this.randomNum(0, str.length)]
            }
        },
        refreshVerifyCode() {
            this.form.verifyCode = ''
            this.generateCode()
        },
        onChangeVerfyCode() {
            this.$nextTick(() => {
                this.$refs['ruleForm'].clearValidate('verifyCode')
            })
        },
        getTradesList(){
            getTradesList().then(res=>{
                this.tradesList = res || []
            })
        },
        getDeptList(){
            getDeptList().then(res=>{
                this.deptList = res || []
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.demand-apply-dialog {
    /deep/ .ant-modal-header {
        border-bottom: none;
        padding: 0 40px;
        .ant-modal-title {
            border-bottom: 2px solid #ebeced;
            padding: 28px 0 28px;
            .title {
                display: flex;
                align-items: center;
                p {
                    font-size: 30px;
                    font-weight: bold;
                    color: #000025;
                }
                .icon-close {
                    margin-left: auto;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                        color: #dbdbdb;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    /deep/ .ant-modal-body {
        padding: 30px 40px;
        .form {
            padding: 0 40px;
            .form-table {
                border: 1px solid #d5d6e3;
                .form-table-row {
                    border-bottom: 1px solid #d5d6e3;
                    &:last-of-type {
                        border-bottom: 1px solid transparent;
                    }
                    .ant-col {
                        display: flex;
                        border-right: 1px solid #d5d6e3;
                        // min-height: 50px;
                        padding: 0 15px;
                        &:last-of-type {
                            border-right: 1px solid transparent;
                        }
                        .form-item-label {
                            height: 50px;
                            line-height: 50px;
                            width: 102px;
                            padding-left: 8px;
                            position: relative;
                            color: #000025;
                            flex-shrink: 0;
                            .require {
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                color: #ff0000;
                            }
                        }
                        .ant-form-item {
                            flex: 1;
                            margin-bottom: 0;
                            .ant-form-item-control-wrapper {
                                padding: 0;
                                .ant-form-item-control {
                                    width: 100%;
                                    line-height: 1;
                                    .ant-input {
                                        width: 100%;
                                        border: 1px solid transparent;
                                        outline: 0;
                                        /* outline: none; */
                                        box-shadow: none;
                                        height: 50px;
                                        padding: 0;
                                    }
                                    textarea {
                                        padding-top: 14px !important;
                                        height: auto !important;
                                        margin: 0;
                                    }
                                }
                                .ant-select-selection {
                                    width: 100%;
                                    border: 1px solid transparent;
                                    outline: 0;
                                    /* outline: none; */
                                    box-shadow: none;
                                    height: 50px;
                                    padding: 0;
                                    .ant-select-selection__rendered,
                                    .ant-select-selection-selected-value {
                                        margin-left: 0;
                                        line-height: 48px;
                                    }
                                }
                            }
                            .ant-form-explain {
                                position: absolute;
                                bottom: 0;
                            }
                        }
                        .verify-code-wrap {
                            display: flex;
                            align-items: center;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }
    }
    /deep/ .ant-modal-footer {
        border-top: none;
        text-align: center;
        padding: 10px 0 40px;
        .btn-submit {
            height: 35px;
            line-height: 35px;
            width: 115px;
            text-align: center;
        }
    }
}
</style>