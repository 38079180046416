<template>
    <div class="container">
        <div class="main-top">
            <div class="main-head">您可以选择以下服务</div>
            <div class="service-list">
                <div class="service-list-item" :class="isServActive == index ? 'service -active' : ''" v-for="(item, index) in serviceData" :key="index" @click="jumpTo(item)">
                    <img class="service-list-item-icon" :src="item.icon_url" alt="" />
                    <p class="service-list-item-name">{{ item.name }}</p>
                    <div class="service-list-item-desc">{{ item.desc }}</div>
                </div>
            </div>
        </div>
        <div class="main-bottom">
            <div class="main-head">数据开放资源目录</div>
            <div class="main-bottom-main">
                <div class="main-bottom-nav">
                    <ul class="main-bottom-nav-list">
                        <li class="main-bottom-nav-list-item" :class="{ liactive: isBottomnavActive == index }" v-for="(item, index) in bottomNav" :key="index" @click="handleNav(index)">{{ item.name }}</li>
                    </ul>
                </div>
                <div class="main-bottom-main-content">
                    <div class="">
                        <div class="main-bottom-btn" :class="isBtnActive == index ? 'btnactive' + index + ' btn' + index : 'btn' + index" v-for="(item, index) in btnData" :key="index" @click="handleBtn(item, index)">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                    <template v-if="menuDataList.length > 0">
                        <div class="main-bottom-list">
                            <div class="main-bottom-list-item" v-for="(item, index) in menuDataList" :key="index">
                                <a-tooltip>
                                    <template slot="title">
                                        {{ item.name || item.resName }}
                                    </template>
                                    <h2 class="main-bottom-list-item-title" @click="handleDetailClick(item)">{{ item.name || item.resName }}</h2>
                                </a-tooltip>
                                <div class="blue-solid"></div>
                                <p class="main-bottom-list-item-time">{{ handleDateToString(item.updateDate, true).split(' ')[0] || handleDateToString(item.releaseDate, true).split(' ')[0] }}</p>
                                <!-- <p class="main-bottom-list-item-time">{{ item.updateDate }}</p> -->
                                <div class="main-bottom-list-item-intro">{{ !isBottomnavActive ? item.resSummary : item.remarks }}</div>
                                <div class="main-bottom-list-item-count">
                                    <p class="main-bottom-list-item-view">
                                        <a-icon type="eye" class="icon" />
                                        <span>{{ item.visitCount || item.visitNum || 0 }}</span>
                                    </p>
                                    <p class="main-bottom-list-item-download">
                                        <a-icon type="download" class="icon" />
                                        <span>{{ item.count || item.downloadNum || 0 }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else style="flex:1;margin-left:20px">暂无数据</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getInterfaceMarket, getDataNew, getDataMore } from './api/index'
import { handleDateToString } from '@/utils/tools'
export default {
    data() {
        return {
            urlId: '', //跳转指定位置的id
            isServActive: -1,
            serviceData: [
                {
                    id: '',
                    icon_url: require('@/assets/index/center-icon1.png'),
                    name: '开放数据',
                    desc: '开放数据集列表，数据下载入口',
                    path: 'OpenData'
                },
                {
                    id: '',
                    icon_url: require('@/assets/index/center-icon2.png'),
                    name: '开放接口',
                    desc: '开放数据接口列表，接口申请入口',
                    path: 'InterfaceService'
                },
                {
                    id: '',
                    icon_url: require('@/assets/index/center-icon3.png'),
                    name: '开发手册',
                    desc: '开放平台简介，新手入门指南',
                    path: 'DeveloperCenterList',
                    navIndex: ''
                },
                {
                    id: 'h2_3',
                    icon_url: require('@/assets/index/center-icon4.png'),
                    name: '应用审核',
                    desc: '开放应用注册接入指南，应用成果说明',
                    path: 'DeveloperCenterList',
                    navIndex: '1_2'
                }
            ],
            isBottomnavActive: -1,
            bottomNav: [{ name: '数据' }, { name: '接口' }],
            isBtnActive: -1,
            btnData: [
                { name: '最新更新', id: '', order: 'onlineTime' },
                { name: '最多下载', id: '', order: 'useCount' }
            ],
            menuDataList: '',
            menuDataList1: []
        }
    },
    created() {},
    mounted() {
        this.handleNav()
    },
    components: {},
    methods: {
        handleDateToString,
        handleNav(index) {
            void (index ? (this.isBottomnavActive = index) : (this.isBottomnavActive = 0)) //默认取开放数据【开放接口】
            this.handleBtn()
        },
        handleBtn(val, index) {
            void (index ? (this.isBtnActive = index) : (this.isBtnActive = 0)) //默认取最新更新【最多下载】
            const data = val ? val : this.btnData[this.isBtnActive]
            this.getData(data)
        },
        getData(data) {
            switch (this.isBottomnavActive) {
                case 0:
                    this.getDataList(data)
                    break
                case 1:
                    this.getInterfaceList(data)
                    break
            }
        },
        getInterfaceList(data) {
            let paramData = {
                pageNo: 1,
                pageSize: 4,
                param: {
                    order: data.order
                }
            }
            getInterfaceMarket(paramData)
                .then(res => {
                    // console.log(res)
                    this.menuDataList = res.records
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getDataList() {
            if (this.isBtnActive == 0) {
                let paramData = {
                    pageNo: 1,
                    pageSize: 4,
                    param: {
                        release: 1
                    }
                }
                getDataNew(paramData).then(res => {
                    this.menuDataList = res.records
                })
            } else {
                let paramData = {
                    pageNo: 1,
                    pageSize: 4,
                    param: {
                        downloadNum: 1
                    }
                }
                getDataMore(paramData).then(res => {
                    // console.log(res)
                    this.menuDataList = res.records
                })
            }
        },
        jumpTo(item) {
            this.$router.push({ name: item.path, params: { id: item.id, navIndex: item.navIndex } })
        },
        handleDetailClick(item) {
            void (this.isBottomnavActive == 0
                ? this.$router.push({
                      name: 'DataSetDetail',
                      query: {
                          id: item.id
                      }
                  })
                : this.$router.push({
                      path: '/InterfaceService/Detail',
                      query: { id: item.id }
                  }))
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    height: 864px;
    position: relative;
    font-family: Source Han Sans CN;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    .main-head {
        min-width: 800px;
        height: 24px;
        text-align: center;
        font-size: 24px;
        line-height: 24px;
        color: #333333;
    }
    .main-top {
        min-width: 800px;
        height: 400px;
        background-image: linear-gradient(180deg, #d0e1fc 0%, #edf3fb 99%, #edf3fb 100%);
        padding: 48px 0 51px;
        .service-list {
            margin: 24px auto;
            max-width: 1200px;
            min-width: 800px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .service-list-item {
                min-width: 200px;
                width: 284px;
                height: 250px;
                background-color: rgba(255, 255, 255, 0.4);
                border: solid 1px #dbdbdb;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .service-list-item-icon {
                    width: 70px;
                    height: 70px;
                    margin-bottom: 20px;
                }
                .service-list-item-name {
                    font-size: 18px;
                    margin-bottom: 24px;
                }
                .service-list-item-desc {
                    font-size: 14px;
                    margin: 0 56px;
                }
            }
            .service-list-item:hover {
                background-color: rgba(53, 125, 241, 0.1);
            }
        }
    }
    .main-bottom {
        height: 467px;
        background-color: #ffffff;
        padding: 51px 0 80px;
        .main-bottom-main {
            // margin: 0 360px;
            margin: 0 auto;
            max-width: 1200px;
            min-width: 800px;
            .main-bottom-nav {
                height: 40px;
                border-bottom: 1px solid #e7e7e7;
                line-height: 40px;
                margin-top: 22px;
                margin-bottom: 20px;
                .main-bottom-nav-list {
                    .main-bottom-nav-list-item {
                        width: 200px;
                        height: 40px;
                        text-align: center;
                        font-size: 18px;
                        color: #999999;
                        float: left;
                        cursor: pointer;
                    }
                    .main-bottom-nav-list-item::after {
                        content: '';
                        width: 0;
                        height: 0;
                        transition-duration: 500ms;
                    }
                    .liactive {
                        color: #333333;
                    }
                    .liactive::after {
                        content: '';
                        width: 200px;
                        height: 3px;
                        background-color: #357df1;
                        border-radius: 1px;
                        display: block;
                    }
                }
            }
            .main-bottom-main-content {
                display: flex;
                justify-content: space-between;
                .main-bottom-btn {
                    width: 240px;
                    height: 110px;
                    border-radius: 2px;
                    margin-bottom: 20px;
                    line-height: 110px;
                    text-align: right;
                    padding-right: 42px;
                    position: relative;
                    span {
                        font-size: 18px;
                        line-height: 18px;
                        color: #333333;
                    }
                }
                .btn0 {
                    background: url('../../assets/index/center-btn1.png') no-repeat;
                    background-size: cover;
                }
                .btnactive0::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    z-index: 99;
                    top: 40px;
                    right: -26px;
                    border: 13px solid transparent;
                    border-left-color: #c9e3fb;
                }
                .btn1 {
                    background: url('../../assets/index/center-btn2.png') no-repeat;
                    background-size: cover;
                }
                .btnactive1::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    z-index: 99;
                    top: 40px;
                    right: -26px;
                    border: 13px solid transparent;
                    border-left-color: #fde0cf;
                }
                .main-bottom-list {
                    width: 940px;
                    min-width: 680px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .main-bottom-list-item {
                        width: 220px;
                        min-width: 140px;
                        height: 240px;
                        background-color: #ffffff;
                        box-shadow: 0px 5px 10px 0px rgba(158, 185, 229, 0.2);
                        border-radius: 2px;
                        padding: 24px 20px 17px 24px;
                        color: #333333;
                        margin-right: 30px;
                        // float: left;
                        .main-bottom-list-item-title {
                            font-size: 16px;
                            margin-bottom: 14px;
                            cursor: pointer;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .blue-solid {
                            width: 40px;
                            height: 2px;
                            background-color: #357df1;
                            border-radius: 1px;
                            margin-bottom: 11px;
                        }
                        .main-bottom-list-item-time {
                            margin-bottom: 23px;
                            font-size: 12px;
                        }
                        .main-bottom-list-item-intro {
                            height: 82px;
                            font-size: 14px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;
                            overflow: hidden;
                        }
                        .main-bottom-list-item-count {
                            font-size: 11px;
                            display: flex;
                            justify-content: space-between;
                            .icon {
                                color: #f58646;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
