<template>
    <div :ref="chartData.ref" :class="chartData.class"></div>
</template>

<script>
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/map'

import 'echarts/lib/component/tooltip' // 提示框
import 'echarts/lib/component/grid' // 网格
import 'echarts/lib/component/title' // title组件
import 'echarts/lib/component/legend' // 图例
import 'echarts-wordcloud' //词云
export default {
    name: 'chart',
    props: {
        chartData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return { myChart: null }
    },
    watch: {
        //  深度监听 父组件刚开始没有值，只有图标的配置项
        //  父组件ajax请求后改变数据的值，传递过来，图标已生成，监听传过来的值的改变
        // deep:true.深度监听，确保data中子项修改也能监听到。写法参考：https://cn.vuejs.org/v2/api/#watch
        chartData: {
            handler(newvalue, oldvalue) {
                // console.log(oldvalue)
                this.drawChart(newvalue)
            },
            deep: true
        }
    },
    created() {},
    mounted() {
        this.$nextTick(() => {
            this.drawChart(this.chartData)
        })
    },
    methods: {
        drawChart(data) {
            // this.myChart = echarts.init(document.getElementById(this.id))
            this.myChart = echarts.init(this.$refs[this.chartData.ref])
            this.myChart.setOption(data.option)
            // window.addEventListener('resize', function() {
            //     this.myChart.resize()
            // })
        }
    },
    beforeDestroy() {
        if (this.myChart) {
            this.myChart.clear()
        }
    }
}
</script>

<style lang="scss" scoped>
.tb1 {
    height: 460px;
}
.tb2 {
    width: 776px;
    height: 470px;
}
.tb3 {
    width: 400px;
    height: 470px;
}
.tb4 {
    height: 460px;
}
.tb5 {
    width: 776px;
    height: 520px;
}
.tb6 {
    width: 400px;
    height: 520px;
}
</style>
