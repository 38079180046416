<template>
    <div class="select-box">
        <label class="name">
            {{ name }}
        </label>
        <a-select :value="value" :disabled="disabled">
            <a-select-option value="" v-for="item in options" :key="item.value">
                {{ item.label }}
            </a-select-option>
        </a-select>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        name: {
            type: String
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    created() {},
    mounted() {},
    methods: {}
}
</script>

<style scoped lang="scss">
.select-box {
    display: flex;
    align-content: center;
    overflow: hidden;
    width: 210px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px #cccccc;
    .name {
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #999;
        width: 80px;
        text-align: right;
        padding-right: 4px;
        background-color: #f0f2f4;
    }
    /deep/ .ant-select {
        flex: 1;
        border: none;
        outline: none;
    }
    /deep/ .ant-select-selection {
        border-color: transparent;
        outline: none;
        box-shadow: none;
        height: 28px;
        line-height: 28px;
        .ant-select-selection__rendered {
            line-height: inherit;
            height: inherit;
            .ant-select-selection-selected-value {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
}
</style>
