export let manual = [
    {
        h1: '总体介绍',
        anchor: '1',
        h2box: [
            {
                h2: '平台简介',
                anchor: '1_1',
                content: '',
                h3box: [
                    { h3: '时代背景', anchor: '1_1_1', content: '大数据时代，政府数据开放已然成为社会发展的必然趋势，目前我国政府公开数据的渠道和方式尚未完善，政府的很多数据还没有被很好地充分开发利用， 数据缺乏流动、传递和扩散，导致企业和个人无法通过正规渠道获取所需的数据资源，同时政府内部，跨部门的数据共享也存在诸多问题。' },
                    { h3: '平台概述', anchor: '1_1_2', content: '本项目旨在推进政府部门的数据共享，方便政府部门将自己的数据进行共享和管理，推动政府数据资源对社会的开放，提升政府与公众、企业的交互水平， 进一步为公众服务。本项目的目标是建立统一的政府数据开放平台，促进政府数据资源的共享和利用。依据资源所属的部门、行业、领域等，对资源进 行分类，方便用户快速找到自己所需的资源。' }
                ]
            },
            {
                h2: '平台价值',
                anchor: '1_2',
                content: '本系统主要用于政府各个部门对可以开放的数据进行归集、整理、分类，对自建的系统接口提供API调用方案，对APP应用和定制开发的网页应用进行下载和公开 调用地址；实名用户可以下载数据集、调用API接口、下载或者嵌入应用。平台能够对数据、接口、应用三方面的数据开放情况进行统计和汇总本系统主要用于政府各个部门对可以开放的数据进行归集、整理、分类，对自建的系统接口提供API调用方案，对APP应用和定制开发的网页应用进行下载和公开 调用地址；实名用户可以下载数据集、调用API接口、下载或者嵌入应用。平台能够对数据、接口、应用三方面的数据开放情况进行统计和汇总。',
                h3box: [
                    { h3: '资源管理', anchor: '1_2_1', content: '资源会被分为3个部分：数据、接口和应用。数据部分会提供数据的预览、接口、下载以及可视化功能。接口部分为用户提供了调用政府开放数据的接口，为了安全考虑， 接口需要用户申请并审核通过后才能调用。应用部分则会提供一些使用政府开放数据进行开发的应用，让用户下载。' },
                    {
                        h3: '数据开放',
                        anchor: '1_2_2',
                        content: '用户注册并实名后，可以在开发者中心创建自己的应用，应用创建成功后，会被分配一个AppSecret，用户在申请接口时需要选择自己所创建的应用， 根据应用的AppSecret来申请接口。当申请在后台被审核通过后，用户才能调用相应的接口。'
                    }
                ]
            }
        ]
    },
    {
        h1: '新手入门',
        anchor: '2',
        h2box: [
            {
                h2: '成为开发者',
                anchor: '2_1',
                content: '数据开放平台提供了用户的注册功能， 用户初次注册时，需要填写自己的真实姓名、身份证号、手机号码、电子邮箱、所属行业、职业等信息。',
                h3box: [{ h3: '开发者权限', anchor: '2_1_1', content: '当用户注册并完成实名认证之后，就会拥有开发者权限，可以在开发者中心创建应用，执行申请调用接口等操作，平台的数据下载也只对注册用户开放。平台的部分功能需要用户完成实名认证之后才能使用，例如"接口申请"、"创建应用"等功能，都需要用户在完成实名制认证后，才能够使用。' }]
            },
            {
                h2: '开放数据集',
                anchor: '2_2',
                content: '开放数据集是指可面向社会公众开放的结构化数据资源，可以查看数据集详细数据项信息。开放门户支持游客查看数据集列表、详情等信息；支持开发者权限用户进行数据集下载。',
                h3box: [{ h3: '数据集下载', anchor: '2_2_1', content: '平台支持开发者用户对开放数据集进行批量打包下载，提供XLS、CSV、JSON、XML、RDF五种格式的数据打包文件下载。对于数据量较少数据集，支持一次性全量下载；对于数据量较大数据集，支持按照更新周期选择下载。' }]
            },
            {
                h2: '开放应用',
                anchor: '2_3',
                content: '开放数据平台支持开发者用户创建个人或企业开放用户，用于对开放数据接口进行绑定申请。对于优秀开放应用，可上架成为开放应用成果，在门户上对外公开下载和使用。',
                h3box: [
                    { h3: '创建应用', anchor: '2_3_1', content: '开发者用户登录平台后进入个人中心-我的应用，点击新增应用，填写好应用名称、应用说明、所属领域、开放着、IP白名单等相关表单信息后，点击提交，等待管理员审批同意通过后视为已正常可用。对于以驳回的开放应用，支持重新编辑提交。' },
                    { h3: '开放应用权限', anchor: '2_3_2', content: '对于注册审批通过正常的开放应用，平台会分配唯一的appKey。对于认证应用可支持申请绑定开放数据接口，若审批通过，则可以将开放数据接口按照协议规范嵌入到关联应用的开发中。' }
                ]
            },
            {
                h2: '数据接口',
                anchor: '2_4',
                content: '开放数据平台除了提供数据集批量下载的开放方式外，还提供数据以接口形式进行对外公开使用。使用开放数据接口需要先创建开放应用，并获得应用认证通过，然后再对所需数据接口发起申请。',
                h3box: [
                    { h3: '数据接口申请', anchor: '2_4_1', content: '可在开放数据接口列表和具体接口详情页发起接口申请操作，需要绑定具体认证正常的开放应用；提交接口申请后，管理员会对申请进行审批；审批通过后，会授予该应用调用该接口的权限，该接口就可以嵌入到关联应用的实际开发中。同一个用户的一个应用可申请多个数据接口。' },
                    { h3: '数据接口调用', anchor: '2_4_2', content: '对于已经申请授权的某个数据接口与某个应用，将生成一条在用服务记录，支持用户查看当前应用对该接口的调用情况。为保障数据的安全性、平台稳定性，应用调用接口需要按照平台协议规范。' }
                ]
            },
            {
                h2: '用户收藏',
                anchor: '2_5',
                content: '数据开放平台支持开发者用户对感兴趣的数据集、数据接口、应用成果进行收藏。',
                h3box: [{ h3: '我的收藏管理', anchor: '2_5_1', content: '开发者用户登录后进入个人中心-我的收藏，可以根据资源类别标签切换具体收藏类目下的资源；支持手动移除；点击具体资源名称，跳转到该资源详情页面。' }]
            }
        ]
    }
]
