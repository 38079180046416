<template>
    <div class="communication">
        <div class="center-content">
            <aside>
                <tab-list :tabList="tabList" :activeTab="activeTab" @clickItem="onClickTab"></tab-list>
            </aside>
            <main>
                <a-spin :spinning="loading" tip="数据加载中...">
                    <div class="communication-top">
                        <strong class="title">{{ tabList[activeTab].name }}</strong>
                        <div v-if="activeTab == 0" class="btn-primary" @click="onClickDemandApply">
                            <a-icon type="plus" style="padding-right: 4px" />
                            需求申请
                        </div>
                        <div v-else-if="activeTab == 1" class="btn-primary" @click="onClickMakeSuggestion">
                            <a-icon type="edit" style="padding-right: 4px" />
                            我要建议
                        </div>
                    </div>
                    <communication-list :list="list"></communication-list>
                    <div class="pagination" v-if="total > 0">
                        <a-pagination :pageSizeOptions="['10', '20', '50']" show-size-changer show-quick-jumper :defaultPageSize="pageProps.pageSize" :current="pageProps.pageNo" :total="total" @showSizeChange="onPageSizeChange" @change="onPageChange" />
                    </div>
                </a-spin>
            </main>
        </div>
        <demand-apply-dialog v-if="demandApplyDialogVisible" @submit="onConfirmDemandApply" @cancel="onCancelDemandApply"></demand-apply-dialog>
        <make-suggestion-dialog v-if="makeSuggestionDialogVisible" @submit="onConfirmMakeSuggestion" @cancel="onCancelMakeSuggestion"></make-suggestion-dialog>
        <login-modal v-if="loginModalVisible" @cancel="loginModalVisible = false"></login-modal>
    </div>
</template>

<script>
import CommunicationList from './components/CommunicationList.vue'
import TabList from './components/TabList.vue'
import { getDataDemandList, getErrorCorrectionList, getPlatformExperienceList } from './api/index'
import DemandApplyDialog from './components/DemandApplyDialog.vue'
import MakeSuggestionDialog from './components/MakeSuggestionDialog.vue'
import LoginModal from '@/components/LoginModal'
import { getCookie } from '@/utils/cookie'
export default {
    components: {
        TabList,
        CommunicationList,
        DemandApplyDialog,
        MakeSuggestionDialog,
        LoginModal
    },
    data() {
        return {
            activeTab: 0,
            tabList: [
                {
                    name: '数据需求',
                    enName: 'SHUJUXUQIU',
                    icon: require('@/assets/communication/数据需求.png'),
                    activeIcon: require('@/assets/communication/数据需求-1.png'),
                    api: getDataDemandList
                },
                {
                    name: '平台体验',
                    enName: 'PINGTAITIYAN',
                    icon: require('@/assets/communication/平台体验.png'),
                    activeIcon: require('@/assets/communication/平台体验-1.png'),
                    api: getPlatformExperienceList
                },
                {
                    name: '数据纠错',
                    enName: 'SHUJUJIUCUO',
                    icon: require('@/assets/communication/数据纠错.png'),
                    activeIcon: require('@/assets/communication/数据纠错-1.png'),
                    api: getErrorCorrectionList
                },
                {
                    name: '权益申诉',
                    enName: 'QUANYISHENSU',
                    icon: require('@/assets/communication/权益申诉.png'),
                    activeIcon: require('@/assets/communication/权益申诉-1.png')
                }
            ],
            pageProps: {
                pageNo: 1,
                pageSize: 10
            },
            total: 0,
            list: [],
            loading: false,
            demandApplyDialogVisible:false,
            makeSuggestionDialogVisible:false,
            loginModalVisible:false,
        }
    },
    computed: {},
    created() {},
    mounted() {
        this.getList()
    },
    methods: {
        // 点击左侧Tab页签方法
        onClickTab(index) {
            // 权益申诉直接跳第三方
            if (index == 3) {
                window.open(window.globalData.quanyishensu, '_blank')
                return
            }
            this.activeTab = index
            this.list = []
            this.resetPageProps()
            this.getList()
        },

        // 页面切换
        onPageChange(current, size) {
            //分页页码切换触发事件
            this.pageProps.pageNo = current //切换页数
            this.pageProps.pageSize = size
            this.getList()
        },

        // 分页切换
        onPageSizeChange(current, size) {
            this.pageProps.pageNo = 1 //切换页数
            this.pageProps.pageSize = size
            this.getList()
        },

        // 重置分页
        resetPageProps() {
            this.pageProps.pageNo = 1
            this.pageProps.pageSize = 10
            this.total = 0
        },

        // 获取列表
        getList() {
            let { api } = this.tabList[this.activeTab]
            if (!api) return
            let { pageNo, pageSize } = this.pageProps
            let params = {
                pageNo,
                pageSize,
                param: {}
            }
            this.loading = true
            api(params)
                .then(res => {
                    let { records, total } = res
                    this.list = records
                    this.total = total
                })
                .catch(err => {
                    err.data.message && this.$message.error(err.data.message)
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 500)
                })
        },

        // 点击“需求申请”
        onClickDemandApply() {
            if (!getCookie('KF_USER')) {
                this.loginModalVisible = true
                return
            }
            this.demandApplyDialogVisible = true
        },
        
        onConfirmDemandApply(){
            this.demandApplyDialogVisible = false
        },
        
        onCancelDemandApply(){
            this.demandApplyDialogVisible = false
        },
        
        // 点击“我要建议”
        onClickMakeSuggestion() {
            if (!getCookie('KF_USER')) {
                this.loginModalVisible = true
                return
            }
            this.makeSuggestionDialogVisible = true
        },
        
        onConfirmMakeSuggestion(){
            this.makeSuggestionDialogVisible = false
        },
        
        onCancelMakeSuggestion(){
            this.makeSuggestionDialogVisible = false
        },
    }
}
</script>

<style lang="scss" scoped>
.communication {
    background: #f2f5f9;
    .center-content {
        max-width: 1200px;
        height: 100%;
        min-height: 565px;
        margin: 0 auto;
        padding: 33px 0 48px;
        // text-align: center;
        display: flex;
        > aside {
            flex-shrink: 0;
            .tab-list {
                .tab-item {
                    width: 175px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    color: #333333;
                    margin-bottom: 20px;
                    background: #fff;
                    cursor: pointer;
                    .tab-icon {
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            max-width: 100%;
                        }
                        margin-left: 15px;
                    }
                    .tab-title {
                        margin-left: 15px;
                        height: 30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        p {
                            font-size: 16px;
                            line-height: 16px;
                        }
                        span {
                            font-size: 10px;
                            line-height: 10px;
                        }
                    }
                    &.active {
                        background-color: #2b85fb;
                        color: #fff;
                    }
                }
            }
        }
        > main {
            margin-left: 20px;
            flex: 1;
            background: #fff;
            padding: 10px;
            min-height: 800px;
        }
        .communication-top {
            display: flex;
            align-items: center;
            padding: 10px;
            .title {
                font-size: 20px;
            }
            .btn-primary {
                height: 30px;
                line-height: 30px;
                padding: 0 18px;
                text-align: center;
                border-radius: 5px;
                font-size: 12px;
                color: #fff;
                background-color: #357df1;
                transition: all 0.3s ease;
                cursor: pointer;
                margin-left: auto;
            }
        }
        .pagination {
            text-align: center;
        }
    }
}
</style>
