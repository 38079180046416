<template>
    <div>
        <div class="headbox">
            <div class="borderTop"></div>
            <slot name="title"></slot>
            <div class="borderBottom"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Headbox',
    props: {},
    data() {
        return { myChart: null }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {}
}
</script>

<style lang="scss" scoped>
.headbox {
    width: 360px;
    height: 48px;
    position: absolute;
    margin: auto;
    left: calc(50% - 180px);
    top: 30px;
    // background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(56, 98, 128), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #2b536f, rgba(0, 0, 0, 0));
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.borderTop {
    z-index: 999;
    width: 300px;
    height: 24px;
    border-top: 1px solid #1ca5b4;
    position: absolute;
    top: 0;
}
.borderBottom {
    z-index: 999;
    width: 300px;
    height: 24px;
    border-bottom: 1px solid #1ca5b4;
    position: absolute;
    bottom: 0;
}
.borderTop::before {
    content: '';
    width: 15px;
    height: 6px;
    background-color: #1ca5b4;
    position: absolute;
    top: -3px;
    left: 0;
}
.borderTop::after {
    content: '';
    width: 15px;
    height: 6px;
    background-color: #1ca5b4;
    position: absolute;
    top: -3px;
    right: 0;
}
.borderBottom::before {
    content: '';
    width: 15px;
    height: 6px;
    background-color: #1ca5b4;
    position: absolute;
    top: 21px;
    left: 0;
}
.borderBottom::after {
    content: '';
    width: 15px;
    height: 6px;
    background-color: #1ca5b4;
    position: absolute;
    top: 21px;
    right: 0;
}
</style>
