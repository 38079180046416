import echarts from 'echarts'
export default deptOpenIndexList => {
    let source = {
        orgName: [],
        数据集: [],
        API: []
    }
    for (let index in deptOpenIndexList) {
        if (deptOpenIndexList[index].orgName) {
            //部门名存在
            source.orgName = [...source.orgName, deptOpenIndexList[index].orgName]
            source['数据集'] = deptOpenIndexList[index].catalogNum ? [...source['数据集'], deptOpenIndexList[index].catalogNum] : [...source['数据集'], 0]
            source['API'] = deptOpenIndexList[index].interfaceNum ? [...source['API'], deptOpenIndexList[index].interfaceNum] : [...source['API'], 0]
        }
    }
    let option = {
        title: {
            text: '市级部门开放指数',
            left: 'center',
            top: 34,
            textStyle: {
                fontSize: 20,
                fontStyle: 'normal',
                color: 'rgba(45, 255, 248, 1)',
                lineHeight: 34,
                textShadowColor: 'rgba(102, 157, 224, 1)'
            },
            subtextStyle: {
                fontSize: 20
            },
            show: true
        },
        dataZoom: {
            type: 'slider',
            borderColor: '#ccc',
            start: 0,
            endValue: deptOpenIndexList.length >= 10 ? deptOpenIndexList[9].orgName : deptOpenIndexList[deptOpenIndexList.length - 1].orgName,
            bottom: 20,
            textStyle: {
                color: '#889cb7'
            }
        },
        legend: [
            // {
            //     left: 54,
            //     top: 44,
            //     itemGap: 16,
            //     itemWidth: 52,
            //     itemHeight: 24,
            //     selectedMode: false, // true single  multiple
            //     inactiveColor: '#ccc' //关闭时颜色
            //     // data:[
            //     //     name
            //     // ]
            // },
            {
                right: 54,
                top: 44,
                itemGap: 47,
                itemWidth: 14,
                itemHeight: 14,
                textStyle: {
                    color: '#889cb7',
                    fontSize: 20
                }
            }
        ],
        tooltip: {},
        dataset: {
            // dimensions: ['orgName', 'dataset', 'api'],
            // source: [
            //     { orgName: '市建设局', dataset: 2500, api: 3000 },
            //     { orgName: '市农业农村局', dataset: 2400, api: 2800 },
            //     { orgName: '市交通运输局', dataset: 2200, api: 2350 },
            //     { orgName: '市自然资源局', dataset: 3400, api: 4200 },
            //     { orgName: '市市场监管局', dataset: 2880, api: 2480 },
            //     { orgName: '市生态环境局', dataset: 3400, api: 2250 },
            //     { orgName: '市药监局', dataset: 1600, api: 2400 },
            //     { orgName: '市文化和旅游局', dataset: 3720, api: 3050 },
            //     { orgName: '市档案馆', dataset: 1897, api: 1484 },
            //     { orgName: '市司法局', dataset: 2260, api: 2718 }
            // ]
            source
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                color: '#889cb7',
                interval: 0,
                formatter: function(value) {
                    var res = value
                    // 长度超过6个的以省略号显示
                    if (res.length > 6) {
                        res = res.substring(0, 7) + '..'
                    }
                    return res
                }
            },
            axisLine: { lineStyle: { color: 'rgba(0, 0, 0,0.2)' } },
            axisTick: { show: false }
        },
        yAxis: { axisLabel: { color: '#889cb7' }, axisLine: { show: false }, axisTick: { show: false }, splitLine: { lineStyle: { color: 'rgba(255, 255, 255,0.2)' } } },
        grid: { top: 113, bottom: 80 },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
            {
                type: 'bar',
                barWidth: 20,
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#2dfff8'
                            },
                            {
                                offset: 1,
                                color: 'rgba(36, 117, 254, 0.1)'
                            }
                        ])
                    }
                }
            },
            {
                type: 'bar',
                barWidth: 20,
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#d86bff'
                            },
                            {
                                offset: 1,
                                color: 'rgba(74, 44, 135, 0.2)'
                            }
                        ])
                    }
                }
            }
        ]
    }
    return option
}
