<template>
    <div class="tab-list">
        <section class="tab-item" v-for="item in list" :key="item.value" :class="item.active ? 'active' : ''" @click="handleClick(item.value)">
            <span class="tab-name">
                {{ item.label }}
            </span>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    created() {},
    mounted() {},
    methods: {
        handleClick(value) {
            this.$emit('switchTab', value)
        }
    }
}
</script>

<style scoped lang="scss">
.tab-list {
    display: flex;
    flex-flow: row nowrap;

    .tab-item {
        position: relative;
        padding: 12px;
        margin-right: 8px;
        cursor: pointer;
        .tab-name {
            color: #999;
            font-weight: bold;
            font-size: $fs14;
        }
    }
    .active {
        .tab-name {
            color: #333;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 2px;
            background-color: $userCenerActive;
        }
    }
}
</style>
