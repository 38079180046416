<template>
    <div class="container">
        <a-spin :spinning="loading" tip="数据加载中...">
            <template v-if="Object.keys(dataInterface).length">
                <div class="wrap">
                    <div class="header">
                        <h2>{{ dataInterface.name }}</h2>
                        <aside @click="handleCollectBtn">
                            <img :src="require('@/assets/index/collected.png')" alt="" v-if="hasCollected" />
                            <img :src="require('@/assets/index/collect.png')" alt="" v-else />
                        </aside>
                        <p class="btn-errorCorrection" @click="onClickErrorCorrection">
                            <img :src="require('@/assets/communication/纠错.png')" />
                            <span>纠错</span>
                        </p>
                    </div>
                    <div class="info-list">
                        <section class="info-item" v-if="dataInterface.remarks">
                            接口摘要
                            <span>{{ dataInterface.remarks }}</span>
                        </section>
                        <section class="info-item" v-if="dataInterface.onlineTime">
                            上线日期
                            <span>{{ dataInterface.onlineTime }}</span>
                        </section>
                        <section class="info-item">
                            更新日期
                            <span>{{ moment(dataInterface.updateDate).format('YYYY-MM-DD HH:mm:ss') }}</span>
                        </section>
                    </div>
                    <div style="margin-top:24px">
                        <p class="wrap-title">
                            接口信息
                        </p>
                        <div class="interface-table">
                            <table v-if="dataInfo.sysCatalog">
                                <tr>
                                    <td>
                                        数据领域
                                    </td>
                                    <td>
                                        {{ getDomainName(dataInfo.sysCatalog.datadomain, dataDomainOptions) }}
                                    </td>
                                    <td>
                                        数据提供单位
                                    </td>
                                    <td>
                                        {{ dataInfo.sysCatalog.idPocName }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>更新周期</td>
                                    <td>{{ showStateLabel(dataInfo.sysCatalog.frequery, frequeryOptions) }}</td>
                                    <td>数据量</td>
                                    <td>{{ dataInfo.sysCatalog.dataCount }}</td>
                                </tr>
                                <tr>
                                    <td>访问次数</td>
                                    <td>{{ dataInterface.visitCount || 0 }}</td>
                                    <td>调用次数</td>
                                    <td>{{ dataInterface.useCount || 0 }}</td>
                                </tr>
                                <tr>
                                    <td>数据预览</td>
                                    <td colspan="3"><a-button type="primary" size="small" style="width:80px;font-size:12px !important" @click="goDataPreview">预览</a-button></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <a-button type="primary" class="btn-apply" @click="goApply" :class="dataInterface.hasApplied ? 'btn-added' : ''">{{ dataInterface.hasApplied ? '已申请' : '申请接口' }}</a-button>
                </div>
                <div class="wrap">
                    <p class="wrap-title">
                        接口详情
                    </p>
                    <div class="interface-table">
                        <table>
                            <tr>
                                <td>接口地址</td>
                                <td>{{ dataInterface.platformUrl }}</td>
                            </tr>
                            <tr>
                                <td>支持格式</td>
                                <td>Content-Type：application/x-www-form-urlencoded</td>
                            </tr>
                            <tr>
                                <td>请求方式</td>
                                <td>{{ showStateLabel(dataInterface.callMode, call_mode_options) }}</td>
                            </tr>
                            <tr>
                                <td>接口描述</td>
                                <td>{{ dataInterface.remarks }}</td>
                            </tr>
                            <tr>
                                <td>请求样例</td>
                                <td>{{ dataInterface.requestShow }}</td>
                            </tr>
                        </table>
                    </div>
                    <p class="wrap-title" style="margin-top:34px" v-if="requestBody.length">请求参数</p>
                    <div class="params-table">
                        <table>
                            <tr>
                                <th>字段名称</th>
                                <th>数据类型</th>
                                <th>是否必传</th>
                                <th>字段描述</th>
                            </tr>
                            <tr v-for="item in requestBody" :key="item.key">
                                <td>{{ item.name }}</td>
                                <td>{{ showStateLabel(item.bianma, dataform_options) }}</td>
                                <td>{{ item.isRequired === true ? '是' : '否' }}</td>
                                <td>{{ item.beizhu }}</td>
                            </tr>
                        </table>
                    </div>
                    <p class="wrap-title" style="margin-top:34px">返回参数</p>
                    <div class="params-table">
                        <table>
                            <tr>
                                <th>字段名称</th>
                                <th>数据类型</th>
                                <th>字段说明</th>
                            </tr>
                            <tr v-for="item in responseBody" :key="item.key">
                                <td>{{ item.name }}</td>
                                <td>{{ item.bianma }}</td>
                                <td>{{ item.beizhu }}</td>
                            </tr>
                        </table>
                    </div>
                    <p class="wrap-title" style="margin-top:34px" v-if="respFieldList.length">返回结果数据项</p>
                    <div class="params-table" v-if="respFieldList.length">
                        <table>
                            <tr>
                                <th>字段名称</th>
                                <th>数据类型</th>
                                <th>字段说明</th>
                            </tr>
                            <tr v-for="item in respFieldList" :key="item.key">
                                <td>{{ item.nameEn }}</td>
                                <td>{{ showStateLabel(item.dataType, dataTypeOptions) }}</td>
                                <td>{{ item.nameCn }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </template>
            <NoData v-else></NoData>
        </a-spin>
        <login-modal v-if="openModal" @cancel="openModal = false"></login-modal>
        <error-correction-dialog v-if="errorCorrectionDialogVisible" :data="errorCorrectionData" @submit="onConfirmErrorCorrection" @cancel="onCancelErrorCorrection"></error-correction-dialog>
    </div>
</template>

<script>
import NoData from '@/components/NoData.vue'
import { showStateLabel, getDeepLabel } from '@/utils/tools.js'
import { mapState } from 'vuex'
import { getCookie } from '@/utils/cookie'
import { getInterfaceInfo, getAllSelectOptions, getInterfaceCollectedList, collectInterface, cancelCollectInterface, getInterfaceApplied, getHasAppliedInterfaces, getRelateAppList } from './api'
import LoginModal from '@/components/LoginModal'
import moment from 'moment'
import ErrorCorrectionDialog from '@/views/InteractiveCommunication/components/ErrorCorrectionDialog'
export default {
    props: { detailId: String },
    data() {
        return {
            id: '',
            relateAppList: [], //我的关联应用列表
            loading: true,
            openModal: false,
            dataInterface: {},
            dataInfo: {},
            requestBody: [],
            responseBody: [
                {
                    name: 'code',
                    bianma: 'Int',
                    beizhu: '200成功，其他异常'
                },
                {
                    name: 'msg',
                    bianma: 'String',
                    beizhu: '返回消息说明'
                },
                {
                    name: 'msg',
                    bianma: 'String',
                    beizhu: '返回消息说明'
                },
                {
                    name: 'data',
                    bianma: 'JSON',
                    beizhu: '返回数据'
                },
                {
                    name: 'current',
                    bianma: 'long',
                    beizhu: '分页页数'
                },
                {
                    name: 'size',
                    bianma: 'long',
                    beizhu: '分页大小'
                },
                {
                    name: 'total',
                    bianma: 'long',
                    beizhu: '数据总量'
                }
            ],
            respFieldList: [],
            requestHead: [],
            responseHead: [],
            dataTypeOptions: [],
            frequeryOptions: [],
            datatype_options: [
                {
                    value: '0',
                    label: 'JSON'
                },
                {
                    value: '1',
                    label: 'XML'
                }
            ],
            public_property_options: [],
            interface_protocol_options: [],
            call_mode_options: [],
            coding_options: [],
            interface_state_options: [],
            dataform_options: [],
            serviceState: '',
            collectedList: [],
            hasCollected: false,
            hasAppliedInterfacesList: [],
            KF_USER: getCookie('KF_USER') ? JSON.parse(getCookie('KF_USER')) : {},
            meta: [
                { name: 'ArticleTitle', content: '数据集接口详情' },
                { name: 'PubDate', content: moment().format('YYYY-MM-DD') },
                { name: 'ContentSource', content: '舟山市数据开放' }
            ],
            errorCorrectionDialogVisible: false,
            errorCorrectionData: {}
        }
    },
    components: {
        LoginModal,
        NoData,
        ErrorCorrectionDialog
    },
    computed: {
        // store取出组织机构，数据领域，资源分类数据
        ...mapState(['dataDomainOptions', 'resClassOptions'])
    },
    created() {
        this.id = this.detailId != undefined && this.detailId != '' ? this.detailId : this.$route.query.id
        this.serviceState = this.$route.query.serviceState
        this.$store.dispatch('getDataDomain')
        this.setMenu()
    },
    mounted() {
        this.getRelateAppList()
        this.getInterfaceCollectedList()
        this.getAllSelectOptions()
        this.getInit() //详情请求
    },
    methods: {
        moment,
        showStateLabel,
        setMenu() {
            for (let item of this.meta) {
                let flag = false
                for (let meta of document.querySelectorAll('meta')) {
                    if (meta.hasAttribute('name') && meta.getAttribute('name') === item.name) {
                        meta.setAttribute('content', item.content)
                        flag = true
                        break
                    }
                }
                if (!flag) {
                    let el = document.createElement('meta')
                    el.setAttribute('name', item.name)
                    el.setAttribute('content', item.content)
                    let firstLink = document.querySelectorAll('head>link')[0]
                    document.getElementsByTagName('head')[0].insertBefore(el, firstLink)
                }
            }
        },
        getDomainName(el, list) {
            let elList = el.split(',')
            let arr = elList.map(item => {
                return getDeepLabel(item, list)
            })
            return arr.join(',')
        },
        getRelateAppList() {
            let params = {
                param: {
                    applicationState: 3
                }
            }
            getRelateAppList(params)
                .then(res => {
                    // console.log('getRelateAppList=>', res)
                    this.relateAppList = res.records || []
                })
                .catch(err => {
                    console.log(err)
                })
        },
        goDataPreview() {
            this.$router.push({
                name: 'DataVisualization',
                query: {
                    id: this.dataInfo.sysCatalog.id
                }
            })
        },
        changeOpenModal(val) {
            this.openModal = val
        },
        getInterfaceCollectedList() {
            //收藏列表
            if (!Object.prototype.hasOwnProperty.call(this.KF_USER, 'id')) {
                this.collectedList = []
                return
            }
            getInterfaceCollectedList({ id: this.KF_USER.id })
                .then(res => {
                    this.collectedList = res || []
                    this.hasCollected = this.collectedList.includes(this.id)
                    console.log('getInterfaceCollectedList=>', res)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        showGoAddAppConfirm() {
            let _this = this
            this.$info({
                title: '您还未注册应用，无法使用接口申请！',
                closable: true,
                okText: '前往应用注册',
                maskClosable: true,
                cancelText: '取消',
                onOk() {
                    _this.$router.push({
                        name: 'MyAppList'
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        goApply() {
            //去申请
            if (!getCookie('KF_USER')) {
                this.openModal = true
                return
            }
            if (this.dataInterface.hasApplied) return
            if (!this.relateAppList.length) {
                //已登录，未申请接口，但是该账号未关联应用，弹出提示去关联应用提示框随后跳到新增关联应用页面
                this.showGoAddAppConfirm()
                return
            }
            getInterfaceApplied({ interfaceId: this.dataInterface.id }).then(res => {
                // debugger
                if (res.open) {
                    if (res.serviceId) {
                        this.$router.push({
                            name: 'InterfaceServiceEdit',
                            params: {
                                id: res.serviceId
                            }
                        })
                    } else {
                        this.$router.push({
                            name: 'InterfaceServiceApplication',
                            params: {
                                id: this.dataInterface.id
                            }
                        })
                    }

                    return
                }
                if (res.catalogHasApplied) {
                    if (res.serviceId) {
                        this.$router.push({
                            name: 'InterfaceServiceEdit',
                            params: {
                                id: this.dataInterface.id
                            }
                        })
                    } else {
                        this.$router.push({
                            name: 'InterfaceServiceApplication',
                            params: {
                                id: this.dataInterface.id
                            }
                        })
                    }
                    return
                }
                this.$message.warn('该接口为受限接口，请先申请对应的数据目录')
            })
        },

        handleCollectBtn() {
            //收藏取消收藏操作
            if (!getCookie('KF_USER')) {
                this.openModal = true
                return
            }
            if (this.hasCollected) {
                this.cancelCollectInterface()
            } else {
                this.collectInterface()
            }
        },
        collectInterface() {
            let params = {
                resourceId: this.dataInterface.id, //id
                resourceName: this.dataInterface.name,
                publishTime: this.dataInterface.onlineTime,
                resourceAbstract: this.dataInterface.remarks, //资源摘要
                resourceProviderId: this.dataInterface.officeCode || '',
                resourceProviderName: this.dataInterface.officeName,
                resourceUpdateTime: this.dataInterface.updateDate || ''
            }
            collectInterface(params)
                .then(() => {
                    this.getInterfaceCollectedList()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        cancelCollectInterface() {
            let params = {
                id: this.dataInterface.id,
                resourceType: 2
            }
            cancelCollectInterface(params)
                .then(() => {
                    this.getInterfaceCollectedList()
                })
                .catch(() => {
                    console.log()
                })
        },
        getAllSelectOptions() {
            let params = {
                types: 'public_property, interface_state, interface_protocol, call_mode,coding,dataform,frequery,dataType'
            }
            getAllSelectOptions(params)
                .then(res => {
                    // console.log('getAllSelectOptions=>', res)
                    this.frequeryOptions = res.frequery
                    this.public_property_options = res.public_property
                    this.interface_protocol_options = res.interface_protocol
                    this.call_mode_options = res.call_mode
                    this.coding_options = res.coding
                    this.dataform_options = res.dataform
                    this.interface_state_options = res.interface_state
                    this.dataTypeOptions = res.dataType
                })
                .catch(err => {
                    // console.log(err)
                })
        },
        getInit() {
            let params = {
                interfaceId: this.id
            }
            this.loading = true
            Promise.allSettled([getHasAppliedInterfaces(), getInterfaceInfo(params)])
                .then(res => {
                    // debugger
                    this.handleHasAppliedInterfacesRes(res[0])
                    this.handleGetDataInerfaceListRes(res[1])
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        handleHasAppliedInterfacesRes(res) {
            //处理我的已申请接口列表
            if (res.status == 'rejected') {
                this.hasAppliedInterfacesList = []
                return
            }
            this.hasAppliedInterfacesList = res.value
        },
        handleGetDataInerfaceListRes(res) {
            //处理获取数据接口列表
            if (res.status == 'rejected') {
                this.dataInfo = {}
                this.dataInterface = {}
                this.loading = false
                return
            }
            this.dataInfo = res.value
            this.dataInterface = res.value.dataInterface
            this.requestBody = res.value.dataInterface.requestBody ? JSON.parse(res.value.dataInterface.requestBody) : []
            this.respFieldList = res.value.respFieldList ? res.value.respFieldList : []
            this.loading = false
            this.dataInterface.hasApplied = this.hasAppliedInterfacesList.includes(this.dataInterface.id)
        },
        onClickErrorCorrection(){
            if (!getCookie('KF_USER')) {
                this.openModal = true
                return
            }
            this.errorCorrectionData = {
                type:2,
                resId:this.dataInterface.id,
                resName:this.dataInfo.sysCatalog.resName,
                dataBaseName:this.dataInfo.sysCatalog.dataBaseName,
                dataItems:this.respFieldList.map(item=>({
                    label:item.nameCn ?? item.nameEn,
                    value:item.nameCn ?? item.nameEn,
                }))
            }
            console.log('this.errorCorrectionData=>',this.errorCorrectionData);
            this.errorCorrectionDialogVisible = true
        },
        onConfirmErrorCorrection(){
            this.errorCorrectionDialogVisible = false
        },
        onCancelErrorCorrection(){
            this.errorCorrectionDialogVisible = false
        },
    }
}
</script>

<style scoped lang="scss">
.container {
    max-width: 1200px;
    min-width: 496px;
    margin: 0 auto;
    padding: 36px 0 16px 0;
    .wrap {
        position: relative;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        padding: 20px;
        margin-bottom: 20px;
        .header {
            display: flex;
            align-items: center;
            h2 {
                font-size: 22px;
                /* font-weight: normal; */
                /* font-stretch: normal; */
                /* line-height: 9px; */
                letter-spacing: 0px;
                color: #303343;
            }
            aside {
                cursor: pointer;
                padding: 0px 30px;
                img {
                    display: block;
                }
            }
            .btn-errorCorrection{
                font-size: 14px;
                color: #ff9600;
                cursor: pointer;
                display: flex;
                align-items: center;
                line-height: 1;
                img{
                    width: 16px;
                    max-height: 100%;
                    margin-right: 4px;
                }
            }
        }
        .info-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 18px;
            .info-item {
                position: relative;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                /* line-height: 9px; */
                letter-spacing: 0px;
                color: #8e92a1;
                padding: 4px 16px;
                &:not(:first-of-type) {
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 18px;
                        background: #303343;
                    }
                }
                &:first-of-type {
                    padding-left: 0;
                }
                > span {
                    padding-left: 8px;
                    color: #303343;
                }
            }
        }
        .wrap-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            /* line-height: 9px; */
            letter-spacing: 0px;
            color: #303343;
            margin-bottom: 28px;
        }
        .interface-table {
            width: 100%;
            table {
                width: 100%;
                table-layout: fixed;
                background-color: #ffffff;
                tr {
                    td {
                        font-size: $fs12;
                        padding: 14px 12px;
                        border: 1px solid #dbdbdb;
                        color: #000025;
                        &:nth-of-type(odd) {
                            width: 140px;
                            text-align: right;
                            color: #8e92a1;
                            background-color: #f9f9f9;
                        }
                    }
                }
            }
        }
        .params-table {
            width: 100%;
            table {
                width: 100%;
                table-layout: fixed;
                background: #fff;
                font-size: $fs12;
                tr {
                    border: 1px solid #dbdbdb;
                    color: #000025;
                    th {
                        background-color: #f9f9f9;
                        padding: 20px 16px;
                    }
                    td {
                        padding: 20px 16px;
                        word-break: break-word;
                    }
                }
            }
        }
        .btn-apply {
            position: absolute;
            right: 20px;
            top: 20px;
            height: 35px;
            border-radius: 18px;
        }
        .btn-added {
            opacity: 0.8;
        }
        .blue-tip {
            position: absolute;
            width: 44px;
            height: 144px;
            left: -44px;
            top: 20px;
            background-color: transparent;
            box-shadow: 0px 3px 7px 0px rgba(110, 129, 148, 0.16);
            color: #fff;
            font-size: 18px;
            z-index: 1;
            p {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 24px;
                text-align: center;
                transform: translate(-50%, -50%);
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                background: #357df1;
                transform: perspective(12px) rotateY(-3deg) scale(1.3);
                transform-origin: right;
                border-bottom-left-radius: 8px;
                border-top-left-radius: 8px;
            }
        }
    }
}
</style>
