import { baseRequest } from '@/API'
export function getApplicationList(type, params) {
    return baseRequest({
        url: `/application/resultopen/appresultlist${type == 'onlineTime' ? 'time' : 'visit'}`,
        method: 'post',
        data: params
    })
}
export function getColllectedList(params) {
    return baseRequest({
        url: '/usercenter/opencollect/selectappresultid',
        method: 'post',
        data: params
    })
}
export function collectAppResult(params) {
    //开放应用收藏提交
    return baseRequest({
        url: '/usercenter/opencollect/appresult',
        method: 'post',
        data: params
    })
}
export function cancelCollectAppResult(params) {
    //开放应用取消收藏
    return baseRequest({
        url: '/usercenter/opencollect/del',
        method: 'post',
        params: params
    })
}
export function getFieldList() {
    return baseRequest({
        url: '/application/resultopen/appresultfield',
        method: 'post'
    })
}
export function getApplicationDetail(params) {
    return baseRequest({
        url: '/application/resultopen/appresultdetail',
        method: 'post',
        data: params
    })
}
export function getAllSelectOptions(params) {
    return baseRequest({
        url: '/catalog/sysdict/selectAll',
        method: 'post',
        params
    })
}
