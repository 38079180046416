<template>
    <div class="container">
        <!-- 政策动态 -->
        <ul class="sidebar">
            <li :class="{ active: isLeftActive == index }" v-for="(item, index) in leftNav" :key="index" @click="handleLeftnav(index)">
                <a-icon class="sidebar-icon" :type="item.icon" />
                <div class="sidebar-txt">{{ item.name }}</div>
            </li>
        </ul>
        <main class="main">
            <template v-if="contentList.length">
                <ul class="content">
                    <li class="content-list" :class="{ noBorder: index > 5 && index == contentList.length - 1 }" v-for="(item, index) in contentList" :key="index" @click="jumpTo(item)">
                        <!-- <li class="content-list" :class="{ noBorder: index > 5 && index == contentList.length - 1 }" v-for="(item, index) in contentList" :key="index" @click="jump(item)"> -->
                        <div class="content-list-title">{{ item.title }}</div>
                        <div class="content-list-info">
                            <span>来源:{{ item.source }}</span>
                            <span>{{ handleDateToString(item.publishtime, false) }}</span>
                        </div>
                    </li>
                    <div class="main-pagination">
                        <a-pagination show-size-changer show-quick-jumper :default-current="current" :total="total" @showSizeChange="onShowSizeChange" @change="onChange" />
                    </div>
                </ul>
            </template>
            <NoData v-else></NoData>
        </main>
    </div>
</template>

<script>
import { getDataShpolicy, getDataNews } from './api/index'
import { handleDateToString } from '@/utils/tools'
import NoData from '@/components/NoData.vue'
export default {
    name: 'PolicyTrends',
    data() {
        return {
            isLeftActive: -1,
            leftNav: [
                { name: '法规政策', path: '', id: '', icon: 'profile' },
                { name: '新闻动态', path: '', id: '', icon: 'profile' }
            ],
            contentList: [],
            pageSize: 10,
            current: 1,
            total: 0
        }
    },
    components: { NoData },
    created() {
        this.paramData = {
            pageNo: this.current,
            pageSize: this.pageSize,
            param: {
                newsType: 1
            }
        }
        this.isLeftActive = this.$route.query.navIndex != undefined ? parseInt(this.$route.query.navIndex) : 0
    },
    mounted() {
        this.handleLeftnav(this.isLeftActive)
    },
    watch: {
        pageSize(val) {
            // console.log('pageSize', val)
            this.pageSize = val
            this.paramData.pageSize = val
            this.getData(this.isLeftActive)
        },
        current(val) {
            let currentPage = val
            if (currentPage > this.total) {
                currentPage = this.total
            }
            if (currentPage <= 0) {
                currentPage = 1
            }
            // console.log('current', val)
            this.current = currentPage
            this.paramData.pageNo = currentPage
            this.getData(this.isLeftActive)
        }
    },
    methods: {
        handleDateToString,
        handleLeftnav(index) {
            this.isLeftActive = index
            this.current = 1
            this.getData(index)
        },
        getData(index) {
            switch (index) {
                case 0:
                    this.getShpolicy()
                    break
                case 1:
                    this.getNews()
                    break
            }
        },
        getShpolicy() {
            getDataShpolicy(this.paramData)
                .then(res => {
                    this.total = res.total
                    this.contentList = res.records
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getNews() {
            getDataNews(this.paramData)
                .then(res => {
                    this.total = res.total
                    this.contentList = res.records
                })
                .catch(err => {
                    console.log(err)
                })
        },
        onShowSizeChange(current, pageSize) {
            // console.log(current, pageSize)
            this.pageSize = pageSize
            // this.getData(this.isLeftActive)
        },
        onChange(current) {
            // console.log('Page: ', current)
            this.current = current
            // this.getData(this.isLeftActive)
        },
        jumpTo(data) {
            this.$router.push({ name: 'PolicyTrendsDetail', params: { id: data.id, breadTitl: this.leftNav[this.isLeftActive].name, title: data.title, publishtime: data.publishtime, source: data.source } })
        },
        jump(data) {
            let details = this.$router.resolve({
                name: 'PolicyTrendsDetail',
                // query: params,
                params: { id: data.id, breadTitl: this.leftNav[this.isLeftActive].name, title: data.title, publishtime: data.publishtime, source: data.source }
            })
            window.open(details.href, '_blank')
        },
        itemRender(current, type, originalElement) {
            if (type === 'prev') {
                return <a>上一页</a>
            } else if (type === 'next') {
                return <a>下一页</a>
            }
            return originalElement
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    max-width: 1200px;
    height: 100%;
    min-height: 722px; //977-255
    position: relative;
    margin: 0 auto;
    padding: 33px 0 48px;
    display: flex;
    font-family: Source Han Sans CN;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    justify-content: space-between;
    align-items: flex-start;
    transition-duration: 500ms;
    .sidebar {
        // max-width: 262px;
        width: 262px;
        min-width: 130px;
        height: 368px;
        background-color: #ffffff;
        border: solid 1px #dbdbdb;
        box-sizing: content-box;
        li {
            height: 60px;
            max-width: 262px;
            min-width: 130px;
            background-color: #ffffff;
            font-size: 14px;
            line-height: 60px;
            color: #000000;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            .sidebar-icon {
                margin: 20px;
            }
            .sidebar-txt {
                flex: 1;
            }
        }
        li::after {
            content: '';
            width: 0;
            height: 0;
            // display: inline-block;
        }
        // .active-nav {
        li.active {
            color: #357df1;
            background-image: linear-gradient(270deg, rgba(53, 125, 241, 0.05) 0%, rgba(53, 125, 241, 0.2) 100%);
        }
        // .active-nav::after {
        li.active::after {
            content: '';
            width: 4px;
            height: 60px;
            background-color: #357df1;
            display: inline-block;
        }
    }
    .main {
        width: 906px;
        min-width: 670px;
        font-size: 18px;
        color: #333333;
        // section {
        //     min-height: 610px;
        //     font-size: 18px;
        //     color: #333333;
        //     transition-duration: 500ms;
        //     display: flex;
        //     flex-direction: column;
        .content {
            // min-height: 610px;
            border: 1px solid #dbdbdb;
            background-color: #ffffff;
            margin-bottom: 24px;
            flex: 1;
            .content-list {
                // width: 904px;
                height: 80px;
                padding: 20px 24px;
                border-bottom: solid 1px #dbdbdb;
                cursor: pointer;
                .content-list-title {
                    font-size: 18px;
                    line-height: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .content-list-title:hover {
                    color: #357df1;
                }
                .content-list-info {
                    margin-top: 10px;
                    font-size: 12px;
                    line-height: 12px;
                    color: #999999;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
            .noBorder {
                border-bottom: none;
            }
            .content-list:hover {
                background-color: #f7f7f7;
            }
        }
        // }
        .main-pagination {
            margin: 15px 0 30px 0;
            text-align: center;
        }
    }
}
</style>
