/********垂直双向滚动到页面指定位置*******/
/**
 * 定长 不定时
 * @param { Number } currentY 当前位置
 * @param { Number } targetY 目标位置
 */
export function scrollAnimation(currentY, targetY) {
    // 获取当前位置方法
    // const currentY = document.documentElement.scrollTop || document.body.scrollTop
    console.log(currentY, targetY)
    // 计算需要移动的距离
    let needScrollTop = targetY - currentY
    let _currentY = currentY
    setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10)
        _currentY += dist
        window.scrollTo(_currentY, currentY)
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
            scrollAnimation(_currentY, targetY)
        } else {
            window.scrollTo(_currentY, targetY)
        }
    }, 1)
}

/**
 * 定时 不定长
 * @param { Number } currentY 当前位置
 * @param { Number } targetY 目标位置
 */
export function scrollTo(currentY, targetY) {
    // let currentY = document.body.scrollTop
    // let targetY = document.getElementById(e).offsetTop
    // console.log('卷积scrollTop:', document.body.scrollTop, '纵坐标offsetTop', targetY)

    // 平滑滚动，时长500ms，每10ms一跳，共50跳【最大偏差49px】
    let newTotal = targetY - currentY
    let step = newTotal > 50 ? (newTotal / 50) >> 0 : 1 //当小于50px时，每跳1px
    if (targetY > currentY) {
        smoothDown()
    } else {
        newTotal = currentY - targetY
        step = newTotal > 50 ? (newTotal / 50) >> 0 : 1 // >>向下取整
        smoothUp()
    }
    function smoothDown() {
        if (currentY < targetY) {
            currentY += step
            document.body.scrollTop = currentY
            setTimeout(smoothDown, 10)
        }
    }
    function smoothUp() {
        if (currentY > targetY) {
            currentY -= step
            document.body.scrollTop = currentY
            setTimeout(smoothUp, 10)
        }
    }
}

export function scrollTo1(currentY, targetY) {
    // 平滑滚动，时长500ms，每10ms一跳，共50跳【最大偏差49px】
    let newTotal = targetY - currentY
    const isPositive = newTotal > 0 ? true : false //true：正数
    let step
    if (newTotal > 50) {
        step = (newTotal / 50) >> 0
    } else if (newTotal <= 50 && newTotal > 0) {
        step = 1
    } else if (newTotal >= -50 && newTotal < 0) {
        step = -1
    } else {
        step = (newTotal / 50) >> 0
    }

    function move() {
        console.log(currentY)
        currentY += step
        document.body.scrollTop = currentY
        if (isPositive) {
            if (currentY < targetY) {
                setTimeout(move, 10)
            }
        } else {
            if (currentY > targetY) {
                setTimeout(move, 10)
            }
        }
    }
    move()
}
